import React, { Component } from 'react';
import PathForm from './PathForm';
import { shouldRender } from 'react-jsonschema-form/lib/utils';
import stylesForm from './PathForm.scss';
import StepContext from './StepContext';

export default class Step extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      liveValidate: this.props.reviewed,
      noValidate: false
    };
    this.isValidated = this.isValidated.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shouldRender(this, nextProps, nextState);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.reviewed !== nextProps.reviewed) {
      this.setState({
        liveValidate: nextProps.reviewed
      });
    }
  }

  // Only called when validation enabled at the step level
  isValidated() {
    if (this.props.noValidate) {
      return true;
    }

    const validation = this.form.validate(this.props.formData, this.props.schema);

    // Set state in form to trigger errors render
    this.setState({
      liveValidate: this.state.liveValidate,
      errors: validation.errors,
      errorSchema: validation.errorSchema
    });

    return validation.errors.length === 0;
  }

  render() {
    const { liveValidate, noValidate } = this.state;
    const { banner = null, children = null } = this.props;
    const contextProviderValue = {
      jumpToStep: this.props.jumpToStep,
      currentStep: this.props.currentStep,
      removeFile: this.props.removeFile ? this.props.removeFile : null
    };

    return (
      <div>
        <StepContext.Provider value={contextProviderValue}>
          {banner}
          <PathForm
            className={stylesForm.pathForm}
            schema={this.props.schema}
            uiSchema={this.props.uiSchema}
            formData={this.props.formData}
            locale={this.props.locale || ''}
            showErrorList={this.props.showErrorList || false}
            liveValidate={liveValidate}
            noValidate={noValidate}
            validate={this.props.customValidate}
            transformErrors={this.props.transformErrors}
            onChange={this.props.onUpdate}
            fields={this.props.fields}
            widgets={this.props.widgets}
            FieldTemplate={this.props.FieldTemplate}
            ref={(form) => {
              this.form = form;
            }}>
            {children || (
              <div className='form-actions'>
                <button id='step_hidden_submit_btn' style={{ display: 'none' }} type='submit' className='btn btn-info'>
                  Submit
                </button>
              </div>
            )}
          </PathForm>
        </StepContext.Provider>
      </div>
    );
  }
}
