import React, { useEffect, useState } from 'react';
import { DatePicker } from 'react-widgets';
import Moment from 'relative-time-parser';
import { errorList } from '../../utils';
import styles from './DateTimeWidget.scss';

function getBound(input) {
  const m = Moment(input);

  if (input) {
    if (Moment().isRelativeTimeFormat(input)) {
      return Moment().relativeTime(input).toDate();
    } else if (m.isValid()) {
      return m.toDate();
    }
  }
}

const DateCalendarWidget = (props) => {
  const [nextView, setNextView] = useState('');
  const [open, setOpen] = useState(false);
  const [state, setWidgetState] = useState({
    min: getBound(props.options?.min),
    max: getBound(props.options?.max),
    useLocalDate: props.options?.useLocalDate || true,
    errors: []
  });
  const [value, setValue] = useState();

  useEffect(() => {
    let value;
    if (props.value) {
      value = convertValueToDisplay(props.value);
    }
    setValue(value);
  }, []);

  useEffect(() => {
    if (props.readonly) {
      setValue(convertValueToDisplay(props.value));
    }
  }, [props.readonly]);

  const setState = (options) => {
    setWidgetState({
      ...state,
      options
    });
  };

  const convertValueToDisplay = (str) => {
    const { useLocalDate } = props.options;
    const m = Moment(str);

    return str && m.isValid() ? (useLocalDate ? m.toDate() : m.local().toDate()) : undefined;
  };

  const convertMomentToOutput = (m) => {
    const { useLocalDate } = props.options;
    const result = useLocalDate ? m.format('YYYY-MM-DD') : m.toISOString(); // m;

    return result;
  };

  const onChange = (value) => {
    const { min, max } = state;
    if (value && min && value < min) {
      setState({
        errors: ['error1']
      });
    } else if (value && max && value > max) {
      setState({
        errors: ['error2']
      });
    } else {
      setOpen(false);
      setNextView('');
      setValue(value);
      setState({ errors: [] });
      if (props.onChange) {
        let valueStr;
        if (value) {
          valueStr = convertMomentToOutput(Moment(value));
        } else if (value) {
          valueStr = convertMomentToOutput(Moment(value));
        }
        props.onChange(valueStr);
      }
    }
  };

  const { autoFocus, disabled, placeholder, options, readonly } = props;
  const { min, max, errors } = state;
  const culture = props.intl && props.intl.locale ? props.intl.locale : navigator.language;

  return (
    <div className={styles.calendar}>
      <DatePicker
        autoFocus={autoFocus}
        calendarProps={{
          views: ['month', 'year', 'decade'],
          onNavigate: () => {
            setOpen(true);
          },
          onViewChange: (nView) => {
            setNextView(nView);
          }
        }}
        disabled={disabled}
        culture={culture}
        max={max}
        min={min}
        onChange={onChange}
        onKeyDown={(e) => e.preventDefault()}
        onToggle={(e) => {
          if (nextView !== 'decade' && nextView !== 'year' && nextView !== 'month') {
            setOpen(e);
          }
        }}
        open={open}
        placeholder={placeholder}
        readOnly={readonly}
        value={value}
        valueFormat={options.format}
      />
      {errorList(errors)}
    </div>
  );
};

export default DateCalendarWidget;
