import React, { useState, useEffect, useRef } from 'react';
import { findDOMNode } from 'react-dom';
import RichTextEditor, { ButtonGroup, Dropdown } from 'react-rte';
import { EditorState, Modifier } from 'draft-js';
import filterXSS from 'xss';
import styles from './RteWidget.scss';

const RteWidget = (props) => {
  const [value, setValue] = useState(RichTextEditor.createValueFromString(' ', 'html'));
  const [convertedValue, setConvertedValue] = useState('');
  const [customControls, setCustomControls] = useState(null);
  const [checksum, setChecksum] = useState(null);
  const [errorStyle, setErrorStyle] = useState({});
  const rteRef = useRef();

  useEffect(() => {
    setValue(RichTextEditor.createValueFromString(props.value || ' ', 'html'));
    if (props.options?.maxLength && props.value) {
      //convert html to string
      let covertedData = props.value.replace(/<[^>]+>/g, '');
      let first50 = covertedData;
      setConvertedValue(typeof covertedData === 'undefined' ? '' : first50);
      if (first50.length > parseInt(props.options?.maxLength, 10)) {
        setErrorStyle({ color: 'red' });
      }
    }
    let tokens = null;
    if (props.options && props.options.tokens && props.options.tokens.length) {
      tokens = (setValue, getValue, editorState) => {
        let choices = new Map([['', { label: 'Insert Field' }]]);
        props.options.tokens.map((t, i) => choices.set(t.token, { label: t.display }));
        return (
          <ButtonGroup key={1}>
            <Dropdown
              choices={choices}
              selectedKey={''}
              onChange={(value) => {
                insertToken(editorState, value);
              }}
            />
          </ButtonGroup>
        );
      };
    }
    setCustomControls([tokens]);

    const rteNode = findDOMNode(rteRef.current);
    const inputs = rteNode.querySelectorAll('select, button');
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].setAttribute('tabindex', -1);
    }
  }, []);

  useEffect(() => {
    if (props.readonly || props.options.checksum != checksum) {
      setValue(RichTextEditor.createValueFromString(props.value || ' ', 'html'));
      //setHtmlValue(props.value);
      setChecksum(props.options.checksum);
    }
  }, [props.readonly, props.options?.checksum]);

  const onChange = (valueInput) => {
    let htmlValueLocal = valueInput.toString('html') !== '<p><br></p>' ? valueInput.toString('html') : '';
    if (props.options?.maxLength) {
      //convert html to string
      let covertedData = htmlValueLocal.replace(/<[^>]+>/g, '');
      let first50 = covertedData;

      if (first50.length > parseInt(props.options.maxLength, 10)) {
        setErrorStyle({ color: 'red' });
      } else {
        setErrorStyle({});
      }
      setConvertedValue(typeof covertedData === 'undefined' ? '' : first50);
      // filter out XSS attacks
      htmlValueLocal = filterXSS(htmlValueLocal);
      setValue(valueInput);
      props.onChange(htmlValueLocal);
    } else {
      // filter out XSS attacks
      htmlValueLocal = filterXSS(htmlValueLocal);
      setValue(valueInput);
      //setHtmlValue(htmlValueLocal);
      props.onChange(htmlValueLocal);
    }
  };

  const insertToken = (editorState, token) => {
    const selectionState = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('TOKEN', 'SEGMENTED', { time: new Date().getTime() });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const modifiedContent = Modifier.insertText(contentState, selectionState, token, ['INSERT'], entityKey);
    const nextState = EditorState.push(editorState, modifiedContent, editorState.getLastChangeType());

    rteRef.current._onChange(nextState);
  };

  const { options } = props;
  let displayArray = ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'];
  if (options && options.displayLinks) {
    displayArray = [
      'INLINE_STYLE_BUTTONS',
      'BLOCK_TYPE_BUTTONS',
      'LINK_BUTTONS',
      'BLOCK_TYPE_DROPDOWN',
      'HISTORY_BUTTONS'
    ];
  }
  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: displayArray,
    INLINE_STYLE_BUTTONS: [
      {
        label: 'Bold',
        style: 'BOLD',
        className: 'custom-css-class',
        tabindex: '-1'
      },
      {
        label: 'Italic',
        style: 'ITALIC',
        clasName: 'custom-css-class',
        tabindex: '-1'
      }
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: 'Normal', style: 'unstyled' },
      { label: 'Heading Large', style: 'header-one' },
      { label: 'Heading Medium', style: 'header-two' },
      { label: 'Heading Small', style: 'header-three' }
    ],
    BLOCK_TYPE_BUTTONS: [
      {
        label: 'UL',
        style: 'unordered-list-item',
        clasName: 'custom-css-class',
        tabindex: '-1'
      },
      {
        label: 'OL',
        style: 'ordered-list-item',
        clasName: 'custom-css-class',
        tabindex: '-1'
      }
    ],
    HISTORY_BUTTONS: [
      {
        label: 'Undo',
        style: 'undo',
        clasName: 'custom-css-class',
        tabindex: '-1'
      },
      {
        label: 'Redo',
        style: 'redo',
        clasName: 'custom-css-class',
        tabindex: '-1'
      }
    ]
  };

  return (
    <div>
      <div className={styles.rte + (props.readonly ? ' ' + styles.rteReadonly : '')}>
        <RichTextEditor
          toolbarConfig={toolbarConfig}
          value={value}
          onChange={onChange}
          disabled={props.disabled}
          readOnly={props.readonly}
          customControls={customControls}
          ref={rteRef}
        />
      </div>
      {props.options?.maxLength && !props.readonly ? (
        <div className='text-right' id='the-count'>
          <span style={errorStyle} id='current'>
            {typeof value === 'undefined' ? '0' : convertedValue.length}
          </span>
          <span id='maximum'>{'/' + props.options?.maxLength}</span>
        </div>
      ) : null}
    </div>
  );
};

export default RteWidget;
