import React, { useState, useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { DropdownList } from 'react-widgets';
import styles from './SelectWidget.scss';

const SelectWidget = (props) => {
  const { t } = useTranslation();
  const { options, value } = props;
  const drodownRef = useRef(undefined);
  const [placeholder] = useState(t(props.placeholder || props.defaultPlaceholder || "Widget.SelectOption" ));
  const [defaultValue, setDefaultValue] = useState(typeof value !== 'object' ? value : value && Object.keys(value).length > 0 ? value : undefined);

  useEffect(() => {
    if (drodownRef && isAutofocus()) {
      drodownRef.current.focus();
    }
  }, []);

  const isAutofocus = () => {
    return props.autofocus;
  };

  const onChange = (inputValue) => {
    setDefaultValue(inputValue ? inputValue.value : undefined);
    if (props.onChange) {
      // Send value out with any processing needed
      props.onChange(inputValue ? inputValue.value : undefined);
    }
  };

  const clear = () => {
    setDefaultValue(undefined);
    props.onChange(undefined);
  };

  const valueInput = (val) => {
   
    const { options } = props;
    if (val.item && val.item.label){
      return (
        <div className={styles.selectitem}>
          {val.item.label}
          {!val.disabled && !options.hideClear ? (
            <span
              className={styles.selectitemclear + ' glyphicon glyphicon-remove'}
              onClick={(e) => {
                clear();
                e.stopPropagation();
              }}
            />
          ) : null}
        </div>
      );
    } else {
      return '';
    }
   
  };

  
  const enumOptions = options.enumOptions;

  return (
    <div className={styles.select}>
      {!props.readonly || (props.readonly && value) ? (
        <DropdownList
          dataKey='value'
          textField='label'
          placeholder={placeholder || options.defaultPlaceholder }
          defaultValue={defaultValue}
          data={enumOptions}
          onChange={onChange}
          disabled={props.disabled}
          readOnly={props.readonly}
          renderValue={valueInput}
          filter={options.filter}
          minLength={options.minLength || 10}
          ref={drodownRef}
          value={defaultValue}
        />
      ) : null}
    </div>
  );
};

export default SelectWidget;
