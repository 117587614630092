import React, { useState } from 'react';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';

import styles from './ContactWidget.scss';

const ContactWidget = (props) => {
  const [showInfoVisibleBtn, setShowInfoVisibleBtn] = useState(true);
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [share, setShare] = useState();

  const onShowInfo = () => {
    const { email = '', share = '', phone = '', showContactFunc } = props;
    setShowInfoVisibleBtn(!showInfoVisibleBtn);
    setEmail(email);
    setPhone(phone);
    setShare(share);
    if (showContactFunc) {
      showContactFunc();
    }
  };

  const showEmail = () => {
    let emailLink = null;
    if (email !== '') {
      emailLink = (
        <div>
          <Glyphicon glyph='envelope' />
          <a href={`mailto:${email}`} className={styles.contactLink}>
            {email}
          </a>
        </div>
      );
    }
    return emailLink;
  };

  const showPhone = () => {
    let phoneLink = null;
    if (phone !== '') {
      phoneLink = (
        <div>
          <Glyphicon glyph='earphone' />

          <a href={`tel:${phone}`} className={styles.contactLink}>
            {phone}
          </a>
        </div>
      );
    }
    return phoneLink;
  };

  const showUrl = () => {
    let urlLink = null;
    if (share !== '') {
      urlLink = (
        <div>
          <Glyphicon glyph='share-alt' />

          <a href={share} className={styles.contactLink}>
            {share}
          </a>
        </div>
      );
    }
    return urlLink;
  };

  const defaultIcons = () => {
    if (showInfoVisibleBtn) {
      return (
        <div className={styles.iconsList}>
          <div>
            <Glyphicon glyph='envelope' />
          </div>
          <div>
            <Glyphicon glyph='earphone' />
          </div>
          <div>
            <Glyphicon glyph='share-alt' />
          </div>
        </div>
      );
    }
  };

  const displayLinks = () => {
    if (!showInfoVisibleBtn) {
      return (
        <div className={styles.iconsList}>
          {showEmail()}
          {showPhone()}
          {showUrl()}
        </div>
      );
    } else {
      return null;
    }
  };

  const { showInfo = '' } = props;
  let infoBtn = null;
  if (showInfoVisibleBtn) {
    infoBtn = (
      <div className={styles.showButton}>
        <button onClick={() => onShowInfo()}>{showInfo}</button>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {defaultIcons()}
      {displayLinks()}
      {infoBtn}
    </div>
  );
};

export default ContactWidget;
