import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDefaultRegistry } from 'react-jsonschema-form/lib/utils';
import styles from '../AccordionField.scss';

const AccordionField = (props) => {
  const [collapse, setCollapse] = useState(false);
  const registry = getDefaultRegistry();
  const { uiSchema, errorSchema, idSchema, name, required, disabled, readonly, schema, formData, autofocus, onBlur } = props;

  const { t } = useTranslation();

  const toggleAccordion = () => {
    setCollapse(!collapse);
  };

  const { SchemaField } = registry.fields;
  let content = uiSchema.content.map((item, index) => {
    return (
      <SchemaField
        key={index}
        name={name}
        required={required}
        schema={schema}
        uiSchema={item}
        errorSchema={errorSchema}
        idSchema={idSchema}
        formData={formData}
        onChange={props.onChange}
        registry={registry}
        disabled={disabled}
        readonly={readonly}
        autofocus={autofocus}
        onBlur={onBlur}
      />
    );
  }, this);

  return (
    <div className={styles.accordion}>
      <div className='panel-group' id={'group-' + uiSchema.title} role='tab' aria-multiselectable='true'>
        <div className='panel panel-default'>
          <div className='panel-heading' role='tab'>
            <h4 className='panel-title'>
              <a
                role='button'
                className={collapse ? 'collapse' : ''}
                data-toggle='collapse'
                href={'#' + uiSchema.title}
                aria-expanded='true'
                aria-controls={'#' + uiSchema.title}
                onClick={() => toggleAccordion()}>
                <span className='title'>{t(uiSchema.title)}</span>
                <span className='toggle-hide'>
                  Hide <span className='qjicon-chevron-up'> </span>
                </span>
                <span className='toggle-show'>
                  Show <span className='qjicon-chevron-down'> </span>
                </span>
              </a>
            </h4>
          </div>
          <div id={uiSchema.title} className={`panel-collapse collapse ` + collapse ? 'in' : ''} role='tabpanel' aria-labelledby='headingOne'>
            <div className='panel-body'>{content}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccordionField;
