import React, { useState } from 'react';
import objectPath from 'object-path';
import { useTranslation } from 'react-i18next';
import { isRequired, resolvePathSettings, errorList, getErrorSchemaPath, getPatch } from '../../utils';


const CheckboxDynamicField = (props) => {
  const label = props.uiSchema.label;
  const dataKey = props.uiSchema.dataKey;
  const textData = dataKey && objectPath.get(props.formData, dataKey) ? objectPath.get(props.formData, dataKey) : label;
  //const [key] = useState(props.uiSchema.key);
  const [value, setvalue] = useState(objectPath.get(props.formData, props.uiSchema.key) || '');
  // const [text] = useState(textData || '');
  const key = props.uiSchema.key;
  const errors = getErrorSchemaPath(props,  props.uiSchema.key).__errors;
  const { disabled, readonly, autofocus } = resolvePathSettings(props);
  const required = isRequired(props, props.uiSchema.key);

  const { t } = useTranslation();

  const onChange = (value) => {
    setvalue(value);
    if (props.onChange) {
      // Send value out with any processing needed
      props.onChange(getPatch(props.uiSchema.key, value));
    }
  };

  // const { key } = props.uiSchema;
  return (
    <div className={(errors ? 'has-error' : '') + ' ' + (readonly ? 'readonly' : '') + ""}>
      <label className='control-label'>
        {t(key)}
        
        {required ? <span className='req'> *</span> : null}
      </label>

      <div className={"checkbox"}>
        <label>
          <input
            type='checkbox'
            checked={typeof value === 'undefined' ? false : value}
            required={required}
            disabled={disabled}
            autoFocus={autofocus}
            onChange={(event) => onChange(event.target.checked)}
          />
          <span>{textData}</span>
        </label>
      </div>

      {errorList(errors)}
    </div>
  );
};

export default CheckboxDynamicField;
