import React, { useState, useRef, useEffect } from 'react';
import NumberFormat from 'react-number-format';

const NumericWidget = (props) => {
  const [value, setValue] = useState();
  const [precision] = useState(props.options && props.options.precision ? props.options.precision : props.precision);
  const [prefix] = useState(props.options && props.options.prefix ? props.options.prefix : props.prefix);
  const [suffix] = useState(props.options && props.options.suffix ? props.options.suffix : props.suffix);
  const [thousandSeparator] = useState(
    props.options && props.options.thousandSeparator ? props.options.thousandSeparator : props.thousandSeparator
  );
  const [decimalSeparator] = useState(
    !precision
      ? '.'
      : props.options && props.options.decimalSeparator
      ? props.options.decimalSeparator
      : props.decimalSeparator
  );
  const [placeholder] = useState(props.placeholder || Number(0).toFixed(precision));
  const [autofocus] = useState(props.autoFocus);
  useEffect(() => {
    if (!typeof value === 'undefined' && !isNaN(value) && value != '') {
      setValue(props.value);
    }
  }, [props.value]);

  const inpRef = useRef();
  const onChange = (value) => {
    if (props.onChange) {
      // Send value out with any processing needed
      if (isNaN(value.value) || value.value === '') {
        props.onChange(undefined);
      } else {
        props.onChange(Number(value.floatValue));
      }
    }
  };

  const focusInput = () => {
    if (inpRef && !inpRef.current) {
      return;
    }
  };

  return (
    <div>
      {!props.readonly ? (
        <div className={prefix || suffix ? 'input-group' : ''}>
          {prefix ? (
            <span className='input-group-addon' onClick={focusInput}>
              {prefix}
            </span>
          ) : null}
          <NumberFormat
            className='form-control'
            value={value}
            onValueChange={(val) => onChange(val)}
            thousandSeparator={thousandSeparator}
            isNumericString
            decimalSeparator={decimalSeparator}
            disabled={props.disabled}
            readOnly={props.readonly}
            placeholder={placeholder}
            autoFocus={autofocus}
            fixedDecimalScale
            decimalScale={precision}
            defaultValue={props.value}
            ref={inpRef}
          />
          {suffix ? (
            <span className='input-group-addon' onClick={focusInput}>
              {suffix}
            </span>
          ) : null}
        </div>
      ) : (
        <span>
          <NumberFormat
            displayType={'text'}
            value={value}
            thousandSeparator={true}
            decimalScale={precision}
            prefix={prefix}
            suffix={suffix}
            disabled={true}
            defaultValue={props.value}
          />
        </span>
      )}
    </div>
  );
};

export default NumericWidget;
