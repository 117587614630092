import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Immutable from 'immutable';
// import { FormattedMessage } from 'react-intl'
import PathForm from './PathForm';
import { defaultTransformErrors } from '../validate';
 import styles from './PathForm.scss';
//const styles = {};
const Review = (props) => {
  const [expanded, setExpanded] = useState([]);
  const [toggle, setToggle] = useState(false);
  const { steps, errorState, uiSchema, formData, liveValidate, dontCollapseErrors = true, confidentialInfo } = props;
  const panelHeadingRef = useRef();
  const { t } = useTranslation();
  const collapseThis = (index) => {

    let newExpanded = expanded ? expanded : [];

    if (newExpanded.includes(index)) {
      // collapse
      newExpanded.splice(newExpanded.indexOf(index), 1);
    } else {
      // open
      newExpanded.push(index);
    }
    setExpanded(newExpanded);
    setToggle(!toggle);
  };


  if (uiSchema.length == 0) {
    return null;
  }


  let tabInvalid = false;

  let tabContent = uiSchema.map((uiSchema, index) => {

    const step = steps[index];
    // Set readonly/disabled
    const uiSchemaWithMoreProps = { ...uiSchema, 'ui:readonly': true, 'ui:disabled': true };

    if (dontCollapseErrors) {
      tabInvalid = errorState[index] && errorState[index].errors && errorState[index].errors.length > 0;

      if (formData) {
        if (step.name === 'Form.ScreeningQuestionnaire.Tab') {
          if (formData.screeningQuestionnaireAnswer && formData.screeningQuestionnaireAnswer.model && formData.screeningQuestionnaireAnswer.model.indexOf('"errors":true') > 0) {
            tabInvalid = true;
          }
        }
        if (step.name === 'Form.OrganisationQuestionnaire.Tab') {
          if (
            formData.organisationQuestionnaireAnswer &&
            formData.organisationQuestionnaireAnswer.model &&
            formData.organisationQuestionnaireAnswer.model.indexOf('"errors":true') > 0
          ) {
            tabInvalid = true;
          }
        }
        if (step.name === 'Form.JobRequisition.Rationale') {
          if (
            formData.jobRequisition &&
            formData.jobRequisition.rationale &&
            formData.jobRequisition.rationale.model &&
            formData.jobRequisition.rationale.model.indexOf('"errors":true') > 0
          ) {
            tabInvalid = true;
          }
        }
      }
    }


    const expand = (Array.isArray(expanded) && expanded.includes(index)) || tabInvalid;
    const newProps = Immutable.fromJS(props).set('uiSchema', uiSchemaWithMoreProps).set('formData', formData).toJS();
    return (
      <div key={index} className='panel panel-default'>
        <div className='panel-heading' role='tab' ref={panelHeadingRef} id={'review-heading' + index}>
          <h4 className='panel-title'>
            <a role='button' onClick={() => collapseThis(index)} className={expand ? '' : 'collapsed'}>
              <span className='title'>
                {' '}
                <span className={step.icon}> </span>{t(`${step.title}`)}  <span className={'qjicon-' + (tabInvalid ? 'alert' : '')}> </span>
              </span>
              <span className='toggle-hide'>
                Hide <span className='qjicon-chevron-up'> </span>
              </span>
              <span className='toggle-show'>
                Show <span className='qjicon-chevron-down'> </span>
              </span>
            </a>
          </h4>
        </div>
        <div id={'review' + index} className={'panel-collapse collapse' + (expand ? ' in' : '')} role='tabpanel' aria-labelledby={'review-heading' + index}>
          <div className='panel-body'>
            <PathForm
              {...newProps}
              className={styles.pathForm}
              showErrorList={false}
              liveValidate={liveValidate}
              validate={step.component.props.customValidate}
              transformErrors={defaultTransformErrors}
              fields={props.fields}
              widgets={props.widgets}
              FieldTemplate={props.FieldTemplate}>
              <div />
            </PathForm>

            {props.jumpToStep ? (
              <div className={styles.alert + (tabInvalid ? ' alert alert-warning' : ' alert alert-default')}>
                <button type='button' className='btn btn-info' onClick={() => props.jumpToStep(index)}>
                  {/* <FormattedMessage id={'Form.Review.Edit'} /> */}
                  {t('Form.Review.Edit')}
                </button>
                {tabInvalid ? (
                  <span className='white-icon'>
                    <span className='qjicon-alert'> </span>{t("Form.Review.Missing")}
                  </span>
                ) : null}
              </div>
            ) : null}
            {step.name === "Form.Application.ConfidentailQuestionnaire" && confidentialInfo ? (
              <button type="button" className="btn btn-info" onClick={props.editConfidentialInfo}>
                {t('Form.Review.Edit')}
              </button>
            ) : null}
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className={styles.review + ' path-form-review'}>
      <div className='panel-group' id='review' role='tablist' aria-multiselectable='true'>
        {tabContent}
      </div>
      {props.children}
    </div>
  );
};

export default Review;
