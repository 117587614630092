import React, { useState } from 'react';
import objectPath from 'object-path';
import { useTranslation } from 'react-i18next';
import { isRequired, resolvePathSettings, errorList, getErrorSchemaPath, getPatch } from '../../utils';


const CharacterInputField = (props) => {
  const label = props.uiSchema.label;
  // const dataKey = props.uiSchema.dataKey;
  const REQUIRED_FIELD_SYMBOL = "*";
  const maxLength = props.uiSchema.schema["ui:maxLength"];
//   const textData = dataKey && objectPath.get(props.formData, dataKey) ? objectPath.get(props.formData, dataKey) : label;
  //const [key] = useState(props.uiSchema.key);
  const [value, setvalue] = useState(objectPath.get(props.formData, props.uiSchema.key) || '');
  // const [text] = useState(textData || '');
  const key = props.uiSchema.key;
  const errors = getErrorSchemaPath(props,  props.uiSchema.key).__errors;
  const { disabled, readonly, autofocus } = resolvePathSettings(props);
  const required = isRequired(props, props.uiSchema.key);

  const { t } = useTranslation();

  const onChange = (value) => {
    if ( maxLength && value.length <= maxLength) {
        setvalue(value);
        if (props.onChange) {
            // Send value out with any processing needed
            props.onChange(getPatch(props.uiSchema.key, value));
        }
    }
  };

  

  // const { key } = props.uiSchema;
  return (
    <div className={(errors ? 'has-error' : '') + ' form-group field field-string ' + (readonly ? 'readonly' : '') + ""}>
      <label className='control-label' htmlFor={"root_"+key.replace(".","_")}>
        {t(key)}
        {required ? <span className='required'>{REQUIRED_FIELD_SYMBOL}</span> : null}
      </label>
          <input
            type='text'
            className="form-control"
            checked={typeof value === 'undefined' ? false : value}
            required={required}
            disabled={disabled}
            autoFocus={autofocus}
            value={value}
            id={"root_"+key.replace(".","_")}
            onChange={(event) => onChange(event.target.value)}
          />
         
       
      {maxLength && !readonly?
                    <div className="text-right" id="the-count">
                        <span id="current">{value.length}</span>
                        <span id="maximum">{"/" + maxLength}</span>
                    </div>
                    :
                    null
                }

      {errorList(errors)}
    </div>
  );
};

export default CharacterInputField;
