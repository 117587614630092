import React from "react";
import { retrieveSchema, shouldRender, getDefaultRegistry } from "react-jsonschema-form/lib/utils";
import { isRequired, getIdSchema, getErrorSchemaPath, getPatch, getSchemaPath, getPath } from "../../utils";

export default class PathField extends React.Component {
  static defaultProps = {
    uiSchema: {},
    formData: [],
    idSchema: {},
    registry: getDefaultRegistry(),
    required: false,
    disabled: false,
    readonly: false,
    autofocus: false
  };
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shouldRender(this, nextProps, nextState);
  }

  onPropertyChange(key) {
    return value => {
      if (this.props.onChange) {
        this.props.onChange(getPatch(key, value));
      }
    };
  }

  render() {
    const { uiSchema, name, disabled, readonly, registry, autofocus, onBlur } = this.props;
    
    const { fields } = registry;
    const { SchemaField } = fields;

    const { definitions } = this.props.schema;
    
    const schema = retrieveSchema(this.props.schema, definitions);
    
    const uiKey = uiSchema.arrayKey ? uiSchema.arrayKey + "." + uiSchema.key : uiSchema.key;

    const subSchema = getSchemaPath(schema, uiSchema.key);
    const formData = getPath(this.props.formData, uiSchema.key);
    const required = isRequired(this.props, uiSchema.key);
    const subErrorSchema = getErrorSchemaPath(this.props, uiSchema.key);
    const subUiSchema = uiSchema.schema;
    return (
      <SchemaField
        required={required}
        name={name}
        schema={subSchema}
        uiSchema={{ ...subUiSchema, uiKey }}
        errorSchema={subErrorSchema}
        idSchema={getIdSchema(this.props, uiSchema.key)}
        formData={formData}
        onChange={this.onPropertyChange(uiSchema.key)}
        registry={registry}
        disabled={disabled}
        readonly={readonly}
        autofocus={autofocus}
        onBlur={onBlur}
      />
    );
  }
}
