import React from 'react';
import ProfilePictureWidget from './ProfilePictureWidget';
import styles from './ProfileWidget.scss';

const ProfileWidget = (props) => {
  const {
    contentStyle,
    name = '',
    jobTitle = '',
    company = '',
    location = '',
    image = '',
    initials = 'NA',
    size = 60
  } = props;

  return (
    <div className={styles.profile}>
      {image ? <ProfilePictureWidget image={image} initials={initials} size={size} /> : null}
      <div className={styles.content} style={contentStyle}>
        <div className={styles.name}>{name}</div>
        <div className={styles.jobTitle}>{jobTitle}</div>
        {company ? <div className={styles.company}>{company}</div> : null}
        {location ? <div className={styles.location}>{location}</div> : null}
      </div>
    </div>
  );
};

export default ProfileWidget;
