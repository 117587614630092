import React from 'react';
import { withTranslation } from 'react-i18next';
import StepZilla from 'react-stepzilla';
import { Navbar, Nav, NavItem, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Scroll from 'react-scroll';
import clone from 'lodash.clone';
import { defaultTransformErrors, validateFormDataUiSchema } from '../validate';
import { getFormDataFromUiSchema } from '../utils';
import styles from './PathForm.scss';
import deepmerge from 'deepmerge';

const scroll = Scroll.animateScroll;

class Stepper extends StepZilla {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      reviewed: this.props.reviewed ? this.props.reviewed : false
    };
    this.onLoad = this.onLoad.bind(this);
    this.onLoad();
  }

  onLoad() {
    this.validateAllSteps();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.alwaysUpdate) {
      return true;
    }
    return (
      this.state.compState !== nextState.compState ||
      this.state.reviewed !== nextState.reviewed ||
      this.props.checksum !== nextProps.checksum
    );
  }

  // overidded to avoid excessive calls to set state which trigger renders
  setNavState(next) {
    let state = { navState: this.getNavStates(next, this.props.steps.length) };

    if (next < this.props.steps.length) {
      state = { ...state, compState: next };
    }

    const prevNextBtnState = this.getPrevNextBtnState(next);
    const newState = { ...prevNextBtnState, ...state };

    this.setState(newState);
  }

  // Override default Stepzilla behaviour
  // removing need for...
  // preventEnterSubmission={ true }
  // which stopped using return in anything!
  handleKeyDown() {}

  jumpToStepWithScroll = (t) => {
    scroll.scrollToTop({ duration: 400 });
    const evt =
      t.target == undefined
        ? {
            persist: () => {},
            preventDefault: () => {},
            stopPropagation: () => {},
            target: { value: t }
          }
        : t;

    // this.stepChange();
    this.hasReviewed(t);
    this.stepChange();
    // if (this.stepChange(evt)) {
    this.jumpToStep(evt);
    // }
  };

  previousWithScroll = () => {
    scroll.scrollToTop({ duration: 400 });
    this.stepChange();
    this.previous();
  };

  nextWithScroll = () => {
    scroll.scrollToTop({ duration: 400 });
    this.hasReviewed(this.state.compState + 1);
    if (this.stepChange()) {
      this.next();
    }
  };

  goToLast = () => {
    this.setNavState(this.props.steps.length - 1);
  };

  stepChange() {
    if (this.isValid() && this.props.enableStepNavigation) {
      this.props.enableStepNavigation();
    }
    if (document.getElementById('step_hidden_submit_btn')) {
      document.getElementById('step_hidden_submit_btn').click();
    }
    const currentStep = this.props.steps[this.state.compState];
    let errorsCurrentTab = this.validateStep(this.state.compState, this.props.formData);
    if (errorsCurrentTab.errors.length > 0 && this.props.mandatoryQuestionsError) {
      let requiredQuestionError = false;
      errorsCurrentTab.errors.map((x) => {
        if (
          x.message?.toLowerCase() === 'field is required' ||
          (x.length > 0 && x[0] === '{organisationQuestionnaire}') ||
          (x.length > 0 && x[0] === '{screeningQuestionnaire}') ||
          (x.length > 0 && x[0] === '{confidentialQuestionnaire}')
        ) {
          requiredQuestionError = true;
          this.props.mandatoryQuestionsError();
        }
      });

      if (!requiredQuestionError && this.props.highlightOtherErrors) {
        this.props.highlightOtherErrors();
      }
    }
    if (
      (errorsCurrentTab && errorsCurrentTab.errors && errorsCurrentTab.errors.length === 0) ||
      this.props.skipValidateStep
    ) {
      if (this.props.onStepChange) {
        this.props.onStepChange();
      }
      if (this.props.jumpLastStepOn > -1 && this.state.compState === this.props.jumpLastStepOn) {
        this.props.popLastStep(() => {
          this.goToLast();
        });
      }
      return true;
    } else if (
      currentStep &&
      currentStep.component.props &&
      currentStep.showRequired &&
      this.props.highlightRequiredQuestions
    ) {
      this.props.highlightRequiredQuestions();
    }

    return false;
  }

  handleAction = (action, customValidate, shouldValidate) => {
    return () => {
      if (customValidate) {
        if (customValidate()) {
          action();
        }
      } else {
        if (shouldValidate) {
          this.setState({ reviewed: true });
          if (this.isValid()) {
            action();
          } else {
            // INVALID
            if (this.props.popError) {
              this.props.popError();
            }
          }
        } else {
          action();
        }
      }
    };
  };

  validateStep(currentStep, formData) {
    const s = this.props.steps[currentStep];
    if (!s || !s.component.props) {
      return [];
    }

    const { schema, uiSchema } = s.component.props;

    let errorScreeningQuestionnaire = false;
    // Check if errors in screening questionnaire
    if (s.name === 'Form.ScreeningQuestionnaire.Tab') {
      let sqModel = formData.screeningQuestionnaireAnswer.model ? formData.screeningQuestionnaireAnswer.model : '';
      if (sqModel.indexOf('"errors":true') > 0) {
        errorScreeningQuestionnaire = true;
      }
    }

    let errorOrgQuestionnaire = false;
    // Check if errors in organisation questionnaire
    if (s.name === 'Form.OrganisationQuestionnaire.Tab') {
      let oqModel = formData.organisationQuestionnaireAnswer.model
        ? formData.organisationQuestionnaireAnswer.model
        : '';
      if (oqModel.indexOf('"errors":true') > 0) {
        errorOrgQuestionnaire = true;
      }
    }

    let errorConfQuestionnaire = false;
    // Check if errors in confidential questionnaire
    if (s.name === 'Form.ConfidentialQuestionnaire.Tab') {
      let cqModel = formData.confidentialQuestionnaireAnswer.model
        ? formData.confidentialQuestionnaireAnswer.model
        : '';

      if (cqModel.indexOf('"errors":true') > 0) {
        errorConfQuestionnaire = true;
      }
    }

    var valFormData = validateFormDataUiSchema(
      formData,
      schema,
      uiSchema,
      s.component.props.customValidate,
      defaultTransformErrors
    );
    if (errorOrgQuestionnaire) {
      let errs = new Array();
      errs[0] = '{organisationQuestionnaire}';
      valFormData.errors.push(errs);
    }
    if (errorScreeningQuestionnaire) {
      let errs = new Array();
      errs[0] = '{screeningQuestionnaire}';
      valFormData.errors.push(errs);
    }
    if (errorConfQuestionnaire) {
      let errs = new Array();
      errs[0] = '{confidentialQuestionnaire}';
      valFormData.errors.push(errs);
    }

    return valFormData;
  }

  validateAllSteps() {
    const errorState = [];
    const { formData } = this.props;
    this.props.steps.map((s, i) => {
      errorState.push(this.validateStep(i, formData));
    });

    return errorState;
  }

  isValid() {
    const errorState = this.validateAllSteps();
    return errorState.map((e) => e.errors.length).filter((length) => length > 0).length === 0;
  }

  hasReviewed(targetStep) {
    const { reviewed } = this.state;
    const { reviewSteps, pristine } = this.props;

    if (typeof reviewSteps !== 'undefined' && reviewSteps !== null && !pristine) {
      this.setState({ reviewed: reviewSteps });
      return reviewSteps;
    } else {
      if (!reviewed && targetStep === this.props.steps.length - 1) {
        this.setState({ reviewed: true });
      }
    }
    return reviewed;
  }

  // main render of stepzilla container
  render() {
    const { compState, valid, reviewed } = this.state;
    const { steps, actions, formData, stepsNavigation = true, t } = this.props;
    const currentStep = compState;

    const renderSteps = () => {
      return (
        <Navbar>
          <Nav className={stepsNavigation ? 'stepNav-on' : 'stepNav-off'}>
            {this.props.steps.map((s, i) => (
              <OverlayTrigger key={i} placement='top' overlay={<Tooltip id={'tooltip-' + i}>{t(s.name)}</Tooltip>}>
                <NavItem className={this.getClassName('step', i)} onClick={() => this.jumpToStepWithScroll(i)}>
                  {s.icon ? (
                    <span className={'tabicon'}>
                      <span className={s.icon}> </span>
                    </span>
                  ) : null}
                  <span className='text'>{t(s.name)}</span>
                  {s.subName ? (
                    <span className={s.required ? 'subText subText--required' : 'subText'}>{t(s.subName)}</span>
                  ) : null}
                </NavItem>
              </OverlayTrigger>
            ))}
          </Nav>
        </Navbar>
      );
    };

    const uiSchema = this.props.steps[compState].component.props.uiSchema;
    let componentFormData = clone(formData);

    if (uiSchema && !Array.isArray(uiSchema)) {
      componentFormData = getFormDataFromUiSchema(formData, uiSchema);
    }

    const compToRender = React.cloneElement(this.props.steps[compState].component, {
      ref: 'activeComponent',
      steps: this.props.steps,
      currentStep: compState,
      errorState: this.props.steps[compState].component.props.validateAllSteps ? this.validateAllSteps() : null,
      stepper: this,
      customValidate: null,
      formData: deepmerge.all([formData, componentFormData]),
      transformErrors: defaultTransformErrors,
      validated: this.props.steps[compState].validated,
      reviewed: reviewed,
      jumpToStep: (t) => {
        this.jumpToStepWithScroll(t);
      }
    });

    const renderActions = () => {
      return actions && actions.length ? (
        <span>
          {actions
            .filter((action) => !action.finalStep || (action.finalStep && currentStep === steps.length - 1))
            .map((a, i) => {
              return (
                <Button
                  key={i}
                  disabled={a.disabled || (a.disableUntilValid && !valid)}
                  onClick={this.handleAction(a.onClick, a.customValidate, a.validate)}
                  className={a.className}
                  bsClass='action'
                  type='button'>
                  {a.title}
                </Button>
              );
            })}
        </span>
      ) : null;
    };

    return (
      <div
        className='multi-step full-height'
        onKeyDown={(evt) => {
          this.handleKeyDown(evt);
        }}>
        <div className={styles.stepper}>
          {this.props.showSteps ? renderSteps() : null}
          <div>
            <h2>
              {steps[currentStep].icon ? <span className={steps[currentStep].icon}> </span> : null}
              {t(steps[currentStep].title)}
            </h2>
            {compToRender}
          </div>
          {this.props.children}
          <div className='form-actions clearfix'>
            {this.props.showNavigation ? (
              <div className='pull-left'>
                <button
                  type='button'
                  style={this.state.showNextBtn ? {} : this.hidden}
                  className='btn btn-next btn-success btn-lg'
                  onClick={() => {
                    this.nextWithScroll();
                  }}>
                  {this.state.nextStepText}
                </button>

                <a
                  role='button'
                  tabIndex='0'
                  style={this.state.showPreviousBtn ? {} : this.hidden}
                  className='btn-prev'
                  onClick={() => {
                    this.previousWithScroll();
                  }}>
                  Back
                </a>
              </div>
            ) : null}
            <div className='pull-right'>{renderActions()}</div>
          </div>
          {this.props.showSteps ? renderSteps() : null}
        </div>
      </div>
    );
  }
}
export default withTranslation()(Stepper);
