import React from 'react';
import ArrayField from 'react-jsonschema-form';
import objectPath from 'object-path';
import SweetAlert from 'react-bootstrap-sweetalert';
import {
  getDefaultFormState,
  getUiOptions,
  isFixedItems,
  allowAdditionalItems,
  retrieveSchema,
  toIdSchema,
  shouldRender,
  getDefaultRegistry
} from 'react-jsonschema-form/lib/utils';
import { isValidPatch } from '../../utils';
import { withTranslation } from 'react-i18next';

function ArrayFieldTitle({ TitleField, idSchema, title, required }) {
  if (!title) {
    // See #312: Ensure compatibility with old versions of React.
    return <div />;
  }
  const id = `${idSchema.$id}__title`;
  return <TitleField id={id} title={title} required={required} />;
}

function ArrayFieldDescription({ DescriptionField, idSchema, description }) {
  if (!description) {
    // See #312: Ensure compatibility with old versions of React.
    return <div />;
  }
  const id = `${idSchema.$id}__description`;
  return <DescriptionField id={id} description={description} />;
}

function IconBtn(props) {
  const { type = 'default', icon, className, ...otherProps } = props;
  return (
    <button
      type='button'
      className={`btn btn-${type} ${className}` + (props.children ? ' btn-text' : '')}
      {...otherProps}>
      {props.children ? props.children : <i className={`glyphicon glyphicon-${icon}`} />}
    </button>
  );
}

// Used in the two templates
function DefaultArrayItem(props, t) {
  const btnStyle = {
    flex: 1,
    paddingLeft: 6,
    paddingRight: 6,
    fontWeight: 'bold'
  };
  return (
    <div key={props.index} className={props.className}>
      <div className='row'>
        <div className={props.hasToolbar ? 'col-xs-10' : 'col-xs-12'}>{props.children}</div>

        {props.hasToolbar ? (
          <div className='col-xs-2 array-item-toolbox'>
            <div className='btn-group' style={{ display: 'flex', justifyContent: 'space-around' }}>
              {props.hasMoveUp || props.hasMoveDown ? (
                <IconBtn
                  icon='arrow-up'
                  className='array-item-move-up'
                  tabIndex='-1'
                  style={btnStyle}
                  disabled={props.disabled || props.readonly || !props.hasMoveUp}
                  onClick={props.onReorderClick(props.index, props.index - 1)}
                />
              ) : null}

              {props.hasMoveUp || props.hasMoveDown ? (
                <IconBtn
                  icon='arrow-down'
                  className='array-item-move-down'
                  tabIndex='-1'
                  style={btnStyle}
                  disabled={props.disabled || props.readonly || !props.hasMoveDown}
                  onClick={props.onReorderClick(props.index, props.index + 1)}
                />
              ) : null}

              {props.hasRemove ? (
                <IconBtn
                  type='danger'
                  icon='trash'
                  className='array-item-remove btn-border'
                  tabIndex='-1'
                  style={btnStyle}
                  disabled={props.disabled || props.readonly}
                  onClick={props.onDropIndexClick(props.index)}>
                  {t('Action.Remove')}
                </IconBtn>
              ) : null}

              {props.confirmDrop === props.index ? (
                <SweetAlert
                  warning
                  showCancel
                  confirmBtnText={t('Form.Array.Confirm.Button')}
                  confirmBtnBsStyle='danger'
                  cancelBtnBsStyle='default'
                  title={t('Form.Array.Confirm.Title')}
                  onConfirm={props.onConfirmDropIndexClick(props.index)}
                  onCancel={props.onHideConfirm()}>
                  {t('Form.Array.Confirm.Message', { item: t(props.uiSchema.itemTitle) })}
                </SweetAlert>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

function DefaultNormalArrayFieldTemplate(props, t) {
  function AddButton({ onClick, disabled, title, autofocus }) {
    return (
      <div className='row'>
        <p className='col-xs-12 array-item-add'>
          <IconBtn
            type='info'
            icon='plus'
            className={'btn-add'}
            tabIndex='0'
            onClick={onClick}
            disabled={disabled}
            autoFocus={autofocus}>
            {t(title)}
          </IconBtn>
        </p>
      </div>
    );
  }

  return (
    <fieldset className={props.className + ' field-array-keyed'}>
      <ArrayFieldTitle
        key={`array-field-title-${props.idSchema.$id}`}
        TitleField={props.TitleField}
        idSchema={props.idSchema}
        title={props.title}
        required={props.required}
      />

      {props.schema.description ? (
        <ArrayFieldDescription
          key={`array-field-description-${props.idSchema.$id}`}
          DescriptionField={props.DescriptionField}
          idSchema={props.idSchema}
          description={props.schema.description}
        />
      ) : null}

      <div className='array-item-list' key={`array-item-list-${props.idSchema.$id}`}>
        {props.items && props.items?.length > 0 && props.items?.map((p) => DefaultArrayItem(p, t))}
      </div>

      {props.canAdd ? (
        <AddButton
          onClick={props.onAddClick}
          disabled={props.disabled || props.readonly}
          title={props.itemAddBtnTitle || 'Action.Add'}
          autofocus={props.autofocus}
        />
      ) : null}
    </fieldset>
  );
}

class PathArrayField extends ArrayField {
  static defaultProps = {
    uiSchema: {},
    formData: [],
    idSchema: {},
    registry: getDefaultRegistry(),
    required: false,
    disabled: false,
    readonly: false,
    autofocus: false
  };

  constructor(props) {
    super(props);
    this.state = {
      confirmDrop: null
    };
    this.onHideConfirm = this.onHideConfirm.bind(this);
    this.onConfirmDropIndexClick = this.onConfirmDropIndexClick.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shouldRender(this, nextProps, nextState);
  }

  onAddClick = (event) => {
    event.preventDefault();
    const { schema, formData } = this.props;
    const { definitions } = schema;

    let itemSchema = schema.items;
    if (isFixedItems(schema) && allowAdditionalItems(schema)) {
      itemSchema = schema.additionalItems;
    }

    this.props.onChange([...formData, getDefaultFormState(itemSchema, undefined, definitions)], { validate: false });
  };

  onDropIndexClick = (index) => {
    return (event) => {
      if (event) {
        event.preventDefault();
      }
      this.setState({ confirmDrop: index });
    };
  };

  onConfirmDropIndexClick = (index) => {
    return (event) => {
      if (event) {
        event.preventDefault();
      }
      const { formData, onChange } = this.props;
      // refs #195: revalidate to ensure properly reindexing errors
      onChange(
        formData.filter((_, i) => i !== index),
        { validate: true }
      );

      this.setState({ confirmDrop: null });
    };
  };

  onHideConfirm() {
    return (event) => {
      if (event) {
        event.preventDefault();
      }
      this.setState({ confirmDrop: null });
    };
  }

  onReorderClick = (index, newIndex) => {
    return (event) => {
      if (event) {
        event.preventDefault();
        event.target.blur();
      }
      const { formData, onChange } = this.props;
      onChange(
        formData.map((item, i) => {
          if (i === newIndex) {
            return formData[index];
          } else if (i === index) {
            return formData[newIndex];
          } else {
            return item;
          }
        }),
        { validate: true }
      );
    };
  };

  onChangeForIndex = (index) => {
    return (value) => {
      const { formData, onChange } = this.props;
      if (isValidPatch(value)) {
        onChange({
          [index]: value
        });
      } else {
        onChange(
          formData.map((item, i) => {
            // We need to treat undefined items as nulls to have validation.
            // See https://github.com/tdegrunt/jsonschema/issues/206
            const jsonValue = typeof value === 'undefined' ? null : value;
            return index === i ? jsonValue : item;
          }),
          { validate: false }
        );
      }
    };
  };

  render() {
    return this.renderNormalArray(this.props.t);
  }

  renderNormalArray(t) {
    const {
      schema,
      uiSchema,
      formData,
      errorSchema,
      idSchema,
      name,
      required,
      disabled,
      readonly,
      autofocus,
      registry,
      formContext,
      onBlur
    } = this.props;

    const { confirmDrop } = this.state;

    const title = schema.title === undefined ? name : schema.title;
    const { ArrayFieldTemplate, definitions, fields } = registry;
    const { TitleField, DescriptionField } = fields;
    const itemsSchema = retrieveSchema(schema.items, definitions);
    const { addable = true } = getUiOptions(uiSchema);

    const arrayProps = {
      canAdd: addable,
      items: formData.map((item, index) => {
        const itemErrorSchema = errorSchema ? errorSchema[index] : undefined;
        const itemIdPrefix = idSchema.$id + '_' + index;
        const itemIdSchema = toIdSchema(itemsSchema, itemIdPrefix, definitions);
        return this.renderArrayFieldItem({
          index,
          canMoveUp: index > 0,
          canMoveDown: index < formData.length - 1,
          itemSchema: itemsSchema,
          itemIdSchema,
          itemErrorSchema,
          itemData: formData[index],
          itemUiSchema: uiSchema.items,
          autofocus: autofocus && index === 0,
          onBlur,
          confirmDrop
        });
      }),
      className: `field field-array field-array-of-${itemsSchema.type}`,
      DescriptionField,
      disabled,
      idSchema,
      onAddClick: this.onAddClick,
      readonly,
      required,
      schema,
      title,
      TitleField,
      formContext,
      autofocus: autofocus && !formData.length,
      confirmDrop,
      itemAddBtnTitle: uiSchema.itemAddBtnTitle
    };

    // Check if a custom render function was passed in
    const renderFunction = ArrayFieldTemplate || DefaultNormalArrayFieldTemplate;
    return renderFunction(arrayProps, t);
  }

  renderArrayFieldItem({
    index,
    canRemove = true,
    canMoveUp = true,
    canMoveDown = true,
    itemSchema,
    itemData,
    itemIdSchema,
    itemErrorSchema,
    autofocus,
    onBlur,
    confirmDrop
  }) {
    const { SchemaField } = this.props.registry.fields;
    const { disabled, readonly, uiSchema } = this.props;
    const { orderable, removable } = {
      orderable: true,
      removable: true,
      ...uiSchema['ui:options']
    };
    const has = {
      moveUp: orderable && canMoveUp,
      moveDown: orderable && canMoveDown,
      remove: removable && canRemove
    };
    has.toolbar = Object.keys(has).some((key) => has[key]);

    const itemType = uiSchema.itemUiSchemaTypeDataKey
      ? objectPath.get(itemData, uiSchema.itemUiSchemaTypeDataKey)
      : null;
    const itemUiSchema = itemType ? uiSchema.itemUiSchemaType[itemType] : uiSchema.itemUiSchema;

    let classNames = '';
    uiSchema.itemClassNameByDataKey &&
      uiSchema.itemClassNameByDataKey.map((o) => {
        classNames += objectPath.get(itemData, o.key) ? ' ' + o.className : '';
      });

    return {
      children: (
        <div>
          {itemUiSchema && itemUiSchema.content
            ? itemUiSchema.content.map((item, i) => (
                <div key={i}>
                  <SchemaField
                    key={index}
                    schema={itemSchema}
                    uiSchema={itemUiSchema}
                    formData={itemData}
                    errorSchema={itemErrorSchema}
                    idSchema={itemIdSchema}
                    onChange={this.onChangeForIndex(index)}
                    onBlur={onBlur}
                    registry={this.props.registry}
                    disabled={this.props.disabled}
                    readonly={this.props.readonly}
                    autofocus={autofocus}
                  />
                </div>
              ))
            : null}
        </div>
      ),
      className: 'array-item' + classNames,
      disabled,
      hasToolbar: has.toolbar,
      hasMoveUp: has.moveUp,
      hasMoveDown: has.moveDown,
      hasRemove: has.remove,
      index,
      onDropIndexClick: this.onDropIndexClick,
      onConfirmDropIndexClick: this.onConfirmDropIndexClick,
      onHideConfirm: this.onHideConfirm,
      onReorderClick: this.onReorderClick,
      readonly,
      itemSchema,
      uiSchema,
      confirmDrop
    };
  }
}

export default withTranslation()(PathArrayField);
