import React from 'react';
import styles from './ProfilePictureWidget.scss';

const ProfilePictureWidget = (props) => {
  const { image = '', initials = 'NA', size = 60 } = props;
  let bShowImage = false;

  if (image !== null && image !== '') {
    bShowImage = true;
  }

  if (bShowImage) {
    // Showing image
    let sizeImg = size + 2; //To account for the border
    return (
      <div
        className={styles.profile}
        style={{
          width: sizeImg,
          height: sizeImg,
          borderRadius: sizeImg,
          backgroundImage: `url(${image})`
        }}>
        <span
          className={`
            ${styles.noImage} 
            ${styles.initials} 
            ${styles.initials}
            ${styles.initialsHidden}`}>
          {initials}
        </span>
      </div>
    );
  } else {
    return (
      <div className={`${styles.profile} ${styles.noImage}`} style={{ width: size, height: size, borderRadius: size }}>
        <span className={styles.initials}>{initials}</span>
      </div>
    );
  }
};

export default ProfilePictureWidget;
