import React, { useState, useEffect } from "react";
import styles from './TagInputWidget.scss';


const SkillTagInputWidget = (props) => {
    const [objArray, setObjArray] = useState([]);
    const [enteredTags, setEnteredTags] = useState([]);
    const [displayValue, setDisplayValue] = useState("");

    useEffect(() => {
        let value = [];
        if ( props.value && typeof props.value === 'string' && props.value.trim() !== "" ) {
            value = JSON.parse(props.value);
        }
        setObjArray(value);
    }, []);

    useEffect( () => {
        initialize();
    },[objArray]);

    const initialize = () => {
        let allEnteredTags = [];
        if (objArray && Array.isArray(objArray)) {
            objArray.map(s => allEnteredTags.push(s.tag));
        }
        setEnteredTags(allEnteredTags);
    };

    const handleEntry = (event) => {
        setDisplayValue(event.target.value);
    };
    
    const handleKeyPress = (event) => {
        let newTag = displayValue.trim();
        
        if (event.key === "Enter" && newTag !== null && newTag !== "" && !enteredTags.includes(newTag)) {
            
            let newObjArray = JSON.parse(JSON.stringify(objArray));
            if (newTag.includes(",")) {
                const arrayNewTags = newTag.match(/(".*?"|[^",]+)(?=\s*,|\s*$)/g);
                const uniqTags = arrayNewTags.filter((v, i, a) => a.indexOf(v) === i);
                uniqTags.map(tag => {
                    if (!enteredTags.includes(tag)) {
                        let cleanedTag = tag.replace(/['"]+/g, "");
                        enteredTags.push(cleanedTag);
                        newObjArray.push({ tag: cleanedTag });
                    }
                });
            } else {
                
                enteredTags.push(newTag);
                if(!Array.isArray(newObjArray)){
                    newObjArray = [];
                    newObjArray.push({ tag: newTag });
                } else {
                    newObjArray.push({ tag: newTag });
                }
            }
            
            setDisplayValue("");
            //setEnteredTags(enteredTags);
            setObjArray(newObjArray);

            if (props.onChange) {
                props.onChange(JSON.stringify(newObjArray));
            }
        }
    };

    const removeTag = (index) => {
        let value = enteredTags[index];
        let cloneEnteredTags = [...enteredTags];
        
        cloneEnteredTags.splice(index, 1);
        setEnteredTags(cloneEnteredTags);
        let newObjArray = objArray.filter(function (obj) {
            return obj.tag !== value;
        });
        
        setObjArray(newObjArray);
        //setEnteredTags(enteredTags);

        if (props.onChange) {
            props.onChange(JSON.stringify(newObjArray));
        }
    };

    const renderTags = () => {
        const { readonly } = props;
        let renderedEnteredTags = null;
        
        if (enteredTags && enteredTags.length) {
            renderedEnteredTags = enteredTags.map((skill, i) => {
                let removeTags = null;
                if (!readonly) {
                    removeTags = (
                        <span className={styles.closeTag} onClick={() => removeTag(i)}>
                            &nbsp;<span className={styles.closeTagGlyph}>&times;</span>&nbsp;
                        </span>
                    );
                }

                return (
                    <span key={i} className={styles.tag}>
                        &nbsp;{skill}&nbsp;{removeTags}
                    </span>
                );
            });
        }
        return <div className={styles.tagsContainer}>{renderedEnteredTags}</div>;
    };

    const { id, readOnly = false } = props;

    return (
        <div className={styles.container}>
            {readOnly ? (
                <pre className="pre-normal">{enteredTags}</pre>
            ) : (
                <div>
                    <input type="text" className={`form-control ` + styles.input} onChange={handleEntry} onKeyPress={handleKeyPress} value={displayValue} onBlur={handleKeyPress} placeholder={props.placeholder} />
                    <input id={id} type="hidden" value={typeof displayValue === "undefined" ? "" : displayValue} />
                </div>
            )}
            {renderTags()}
        </div>
    );

};

export default SkillTagInputWidget;
