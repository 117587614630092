import React from 'react';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import styles from './ListTagWidget.scss';

const ListTagWidget = (props) => {
  const { name = '', glyphicon = '', handleTagClick, isHighlighted } = props;

  let glyph = null;
  if (glyphicon !== '') {
    glyph = <Glyphicon glyph={glyphicon} />;
  }
  return (
    <span
      className={`${styles.listTag} ${isHighlighted ? styles.highlightedTag : ''} ${
        handleTagClick ? styles.moreTag : ''
      }`}>
      {glyph}
      {handleTagClick !== undefined ? (
        <span onClick={() => handleTagClick()} className={styles.listTagLabel}>
          {name}
        </span>
      ) : (
        <span className={styles.listTagLabel}>{name}</span>
      )}
    </span>
  );
};

export default ListTagWidget;
