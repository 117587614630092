import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker, DropdownList } from 'react-widgets';
import Moment from 'relative-time-parser';
import { errorList } from '../../utils';
import styles from './DateTimeWidget.scss';
import moment from 'moment';

moment.locale('en-NZ');

function getBound(input) {
  const m = Moment(input);
  if (input) {
    if (Moment().isRelativeTimeFormat(input)) {
      return Moment().relativeTime(input).toDate();
    } else if (m.isValid()) {
      return m.toDate();
    }
  }
}

const DateTimeCalendarWidget = (props) => {
  const DateTimePickerRef = useRef();
  const convertValueToDisplay = (str) => {
    const { useLocalDate } = props.options;
    var m = Moment(str);
    if (useLocalDate) {
      var offsetInMinutes = m.utcOffset() * -1;
      m = m.utc().add(offsetInMinutes, 'm');
    }
    return str && m.isValid() ? (useLocalDate ? m.toDate() : m.local().toDate()) : undefined;
  };

  const { t } = useTranslation();
  const [dateValue, setDateValue] = useState(props.value ? convertValueToDisplay(props.value) : null);
  const [state, setWidgetState] = useState({
    min: getBound(props.options?.min),
    max: getBound(props.options?.max),
    time: props.options?.time || false,
    useLocalDate: props.options?.useLocalDate || false,
    calendar: Object.prototype.hasOwnProperty.call(props.options, 'calendar') ? props.options.calendar : 'true',
    errors: []
  });

  useEffect(() => {
    if (props.autofocus) {
      DateTimePickerRef.current.focus();
    }
    if (props.readonly) {
      setDateValue(convertValueToDisplay(props.value));
    }
  }, []);

  const setState = (options) => {
    setWidgetState({
      ...state,
      options
    });
  };

  const convertMomentToOutput = (m) => {
    const { useLocalDate } = props.options;
    const convertedValue = useLocalDate ? m.format('YYYY-MM-DD[T]HH:mm:ss') + '+00:00' : m.toISOString();
    return convertedValue;
  };

  const onChange = (value) => {
    const { min, max, time } = state;
    if (value && min && value < min) {
      setState({
        errors: [t('Form.Errors.DateMinDate')]
      });
    } else if (value && max && value > max) {
      setState({
        errors: [t('Form.Errors.DateMaxDate')]
      });
    } else {
      setDateValue(value);
      setState({ errors: [] });
      if (props.onChange) {
        let valueStr;
        if (value && time) {
          valueStr = convertMomentToOutput(Moment(value));
        } else if (value) {
          valueStr = convertMomentToOutput(Moment(value).startOf('day'));
        }
        setDateValue(valueStr);
        props.onChange(valueStr);
      }
    }
  };

  const getTimeList = () => {
    var hours = [];
    var quarterHours = ['00', '15', '30', '45'];
    for (let hour = 0; hour < 24; hour++) {
      for (var j = 0; j < quarterHours.length; j++) {
        if (j <= 0) {
          hours.push(moment({ hour }).format('h:mm A'));
        } else {
          hours.push(
            moment({
              hour,
              minute: quarterHours[j]
            }).format('h:mm A')
          );
        }
      }
    }
    return hours;
  };

  const onChangeTime = (value) => {
    setDateValue(value);
    if (props.onChange) {
      props.onChange(value);
    }
  };

  const { placeholder, options } = props;
  const { min, max, time, errors } = state;
  const culture = props.intl && props.intl.locale ? props.intl.locale : navigator.language;
  const calViews =
    options.initialView && options.finalView
      ? [options.initialView, options.finalView]
      : ['month', 'year', 'decade', 'century'];
  const valueDisplayFormat =
    options.format && options.format === 'MM/YYYY'
      ? { month: '2-digit', year: 'numeric' }
      : { month: '2-digit', day: '2-digit', year: 'numeric' };

  return (
    <div className={styles.calendar}>
      {state !== {} &&
        (time ? (
          <DropdownList
            dataKey='id'
            value={moment(dateValue).isValid() ? moment(dateValue).format('LT') : dateValue}
            data={getTimeList()}
            onChange={onChangeTime}
            textField='label'
            className='w-2/5 mt-0'
            placeholder='9:00 AM'
          />
        ) : (
          <DatePicker
            format={options.format && options.format === 'MM/YYYY' ? 'mm YYYY' : 'mm dd YYYY'}
            ref={DateTimePickerRef}
            defaultValue={dateValue}
            onChange={onChange}
            disabled={props.disabled}
            readOnly={props.readonly}
            placeholder={placeholder}
            min={min}
            max={max}
            includeTime={time}
            step={15}
            culture={culture}
            calendarProps={{ defaultView: options.initialView || 'month', views: calViews }}
            // Here add readonly as hack, react-widget is not parsing manually enter date hence disabled input box.
            // https://github.com/jquense/react-widgets/issues/725
            inputProps={{
              readOnly: true
            }}
            valueFormat={valueDisplayFormat}
          />
        ))}
      {errorList(errors)}
    </div>
  );
};

export default DateTimeCalendarWidget;
