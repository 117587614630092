import React, { useState } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

const SelectMultiWidget = (props) => {
  const [tags, setTags] = useState([]);
  const [schema] = useState(props.schema);

  const stringifyProps = (props, tags) => {
    return {
      value: stringifyValue(props.value),
      options: stringifyOptions(props.options.enumOptions).concat(tags),
      tagging: props.options.tagging
    };
  };

  const stringifyValue = (value) => value.map((v) => JSON.stringify(v));

  const stringifyOptions = (options) =>
    options.map((o) => {
      return { label: o.label || o.value, value: JSON.stringify(o.value) };
    });
  const [state, setState] = useState(stringifyProps(props, tags));

  const onChange = (value) => {
    if (value.length > 1 && schema.maxItems && value.length > schema.maxItems) {
      value.splice(value.length - 2, 1);
    }
    let newoptions = { ...state };
    newoptions.value = value.map((v) => v.value);

    setState(newoptions);
    if (props.onChange) {
      // Send value out with any processing needed
      props.onChange(value.map((v) => JSON.parse(v.value)));
    }
  };

  const onCreate = (name) => {
    const { tagging, value } = state;
    if (tagging) {
      const tag = { label: name, value: JSON.stringify(name) };
      const valueChanged = value.concat(tag.value);
      if (valueChanged.length > 1 && schema.maxItems && valueChanged.length > schema.maxItems) {
        valueChanged.splice(valueChanged.length - 2, 1);
      }
      const concatTag = tags.concat(tag);

      let newoptions = { ...state };
      newoptions.value = valueChanged;
      newoptions.options = [...newoptions.options, tag];
      setTags(concatTag);
      setState(newoptions);
      props.onChange(newoptions.value.map((v) => JSON.parse(v)));
      // this.setState({ tags: tags.concat(tag), value }, () => this.props.onChange(value.map(v => JSON.parse(v))));
    }
  };
  const { options, value, tagging } = state;

  let selectedObj = [];
  options.forEach((v) => {
    if (value.indexOf(v.value) >= 0) {
      selectedObj.push(v);
    }
  });
  return (
    <div className='select'>
      {options && options.length ? (
        tagging ? (
          <CreatableSelect
            isClearable
            onChange={onChange}
            onCreateOption={onCreate}
            options={options}
            className='basic-multi-select'
            classNamePrefix='select'
            isDisabled={props.disabled || props.readonly}
            isMulti
            defaultValue={selectedObj}
            value={selectedObj}
          />
        ) : (
          <Select
            isMulti
            options={options}
            loadingMessage={'loading...'}
            onChange={onChange}
            // value={value}
            className='basic-multi-select'
            classNamePrefix='select'
            isDisabled={props.disabled || props.readonly}
            // defaultValue={value}
            selectProps='value'
            defaultValue={selectedObj}
          />
        )
      ) : null}
    </div>
  );
};

export default SelectMultiWidget;
