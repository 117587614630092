import React, { useEffect, useState } from "react";
import styles from './ToggleSliderWidget.scss';

const ToggleSliderWidget = (props) => {
    const [value, setValue] = useState(props.value);
    const { disabled = false, autofocus = false } = props;
    const randomKey = Math.floor(Math.random() * Math.floor(10000));

    useEffect(() => {
        setValue(props.value);
    }, [props.readonly]);

    const onToggle = e => {
        e.stopPropagation();
        if (props.onChange) {
            // Send value out with any processing needed
            props.onChange(!value);
        }
        setValue(!value);
    };

    return (
        <div className={styles.wrapper}>
            <button type="button" className={value ? `form-control ${styles.btnToggle} ${styles.active}` : `form-control ${styles.btnToggle}`} disabled={disabled} autoFocus={autofocus} onClick={onToggle}>
                <div className={styles.handle} />
            </button>
            <input type="hidden" key={randomKey} value={props.value} />
        </div>
    );
};

export default ToggleSliderWidget;
