import React, { useState } from "react";
import styles from './TagInputWidget.scss';

const TagInputWidget = (props) => {
    let value = [];
    if ( props.value && Array.isArray(props.value)  ) {
        value = props.value;
    }
    const [enteredTags, setEnteredTags] = useState( value);
    const [displayValue, setDisplayValue] = useState("");

    const handleEntry = (event) => {
        setDisplayValue(event.target.value);
    };

    const handleKeyPress = (event) => {
        let newTag = displayValue.trim();

        if (event.key === "Enter" && newTag !== null && newTag !== "" && !enteredTags.includes(newTag)) {
            if (newTag.includes(",")) {
                const arrayNewTags = newTag.match(/(".*?"|[^",]+)(?=\s*,|\s*$)/g);
                const uniqTags = arrayNewTags.filter((v, i, a) => a.indexOf(v) === i);
                uniqTags.forEach(tag => {
                    if (!enteredTags.includes(tag)) {
                        enteredTags.push(tag.replace(/['"]+/g, ""));
                    }
                });
            } else {
                enteredTags.push(newTag);
            }

            setDisplayValue("");
            setEnteredTags(enteredTags);
            if (props.onChange) {
                props.onChange(enteredTags);
            }
        }
    };

    const removeTag = (index) => {
        // const { enteredTags } =  state;
        enteredTags.splice(index, 1);
        setEnteredTags(enteredTags);
        if (props.onChange) {
            props.onChange(enteredTags);
        }
    };

    const renderTags = () => {
        // enteredTags = [];
        const { readonly } = props;
        let renderedEnteredTags = null;

        if (enteredTags && Array.isArray(enteredTags)) {
            renderedEnteredTags = enteredTags.map((skill, i) => {
                let removeTags = null;
                if (!readonly) {
                    removeTags = (
                        <span className={styles.closeTag} onClick={() => removeTag(i)}>
                            &nbsp;<span className={styles.closeTagGlyph}>×</span>&nbsp;
                        </span>
                    );
                }

                return (
                    <span key={i} className={styles.tag}>
                        &nbsp;{skill}&nbsp;{removeTags}
                    </span>
                );
            });
        }
        return <div className={styles.tagsContainer}>{renderedEnteredTags}</div>;
    };


    const { id, readOnly = false } = props;

    return (
        <div className={styles.container}>
            {readOnly ? (
                <pre className="pre-normal">{enteredTags}</pre>
            ) : (
                <div>
                    <input type="text" className={`form-control ` + styles.input} onChange={handleEntry} onKeyPress={handleKeyPress} value={displayValue} onBlur={handleKeyPress} placeholder={props.placeholder} />
                    <input id={id} type="hidden" value={typeof displayValue === "undefined" ? "" : displayValue} />
                </div>
            )}
            {renderTags()}
        </div>
    );
};

export default TagInputWidget;