import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import objectPath from 'object-path';
import update from 'immutability-helper';
import deepmerge from 'deepmerge';
import { DatePicker } from 'react-widgets';
import Moment from 'relative-time-parser';
import { isRequired, resolvePathSettings, getOptions, errorList, getErrorSchemaPath, getPatch } from '../../utils';

function getBound(input) {
  const m = Moment(input);
  if (input) {
    if (Moment().isRelativeTimeFormat(input)) {
      return Moment()
        .relativeTime(input)
        .toDate();
    } else if (m.isValid()) {
      return m.toDate();
    }
  }
}

const DateRangeField = (props) => {
  const { t } = useTranslation();

  const values = props.uiSchema.values;
  const from = objectPath.get(props.formData, values[0].key);
  const to = objectPath.get(props.formData, values[1].key);
  const options = getOptions(props.uiSchema.schema);

  const stateValue = {
    from: {
      key: values[0].key,
      value: from ? new Date(from) : null,
      min: getBound(values[0].min),
      max: getBound(values[0].max),
      errors: []
    },
    to: {
      key: values[1].key,
      value: to ? new Date(to) : null,
      min: getBound(values[1].min),
      max: getBound(values[1].max),
      errors: []
    }
  };

  const [state, setDateRangeValue] = useState(stateValue);
  const [fromDateNavigate, setFromDateNavigate] = useState(false);
  const [toDateNavigate, setToDateNavigate] = useState(false);

  const setState = (options, callback = false) => {
    setDateRangeValue({
      ...state,
      ...options,
    });
    if (callback) {
      callback();
    }
  };

  const [nextView, setnextView] = useState('');
  const [open, setopen] = useState(false);
  const [nextViewEndDate, setnextViewEndDate] = useState('');
  const [openEndDate, setopenEndDate] = useState(false);

  useEffect(() => {
    if (props.readonly) {
      componentWillReceiveProps(props);
    }
  }, [props]);

  const componentWillReceiveProps = (nextProps) => {
    if (props.readonly) {
      // Readonly so keep the state synced
      const from = objectPath.get(nextProps.formData, values[0].key);
      const to = objectPath.get(nextProps.formData, values[1].key);

      const newState = update(state, deepmerge.all([getPatch("from.value", from ? new Date(from) : null), getPatch("to.value", to ? new Date(to) : null)]));
      setState(newState);
    }
  };

  const onChangeFrom = from => {
    let to = state.to.value;
    let newState = state;
    setopen(false);
    setnextView("");
    setFromDateNavigate(false);
    if (from && state.from.min && from < state.from.min) {
      newState = update(
        state,
        getPatch("from.errors", [
          t("Form.Errors.DateMinDate")
        ])
      );
    } else if (from && state.from.max && from > state.from.max) {
      newState = update(
        state,
        getPatch("from.errors", [
          t("Form.Errors.DateMaxDate")
        ])
      );
    } else if (to && to < from) {
      to = null;
      newState = update(
        state,
        deepmerge.all([
          getPatch("from.value", from),
          getPatch("to.value", to),
          getPatch("to.errors", [
            t("Form.Errors.DateRangeEndClearedBeforeStart")
          ])
        ])
      );
    } else {
      newState = update(state, deepmerge.all([getPatch("from.value", from && !isNaN(from) ? from : ''), getPatch("from.errors", [])]));
    }

    setState(newState);
    onChange(from, to);
  };

  const onChangeTo = to => {
    var from = state.from.value;
    let newState = state;
    setopenEndDate(false);
    setnextViewEndDate("");
    setToDateNavigate(false);
    if (to && state.to.min && to < state.to.min) {
      newState = update(
        state,
        getPatch("to.errors", [
          t("Form.Errors.DateMinDate")
        ])
      );
    } else if (to && state.to.max && to > state.to.max) {
      newState = update(
        state,
        getPatch("to.errors", [
          t("Form.Errors.DateMaxDate")
        ])
      );
    } else if (to && to < from) {
      newState = update(
        state,
        getPatch("to.errors", [
          t("Form.Errors.DateRangeEndBeforeStart")
        ])
      );
    } else {
      newState = update(state, deepmerge.all([getPatch("to.value", to && !isNaN(to) ? to : ''), getPatch("to.errors", [])]));
    }

    setState(newState);
    onChange(from, to);
  };

  const onChange = (from, to) => {
    props.onChange(deepmerge.all([getPatch(state.to.key, to && !isNaN(to) ? to.toISOString() : ""), getPatch(state.from.key, from && !isNaN(from) ? from.toISOString() : "")]));
  };

  const { placeholder } = options;
  const { disabled, readonly } = resolvePathSettings(props);

  const fromErrors = getErrorSchemaPath(props, state.from.key).__errors;
  const toErrors = state.to.errors.length
    ? state.to.errors.concat(getErrorSchemaPath(props, state.to.key).__errors).filter(function (n) {
      return n != undefined;
    })
    : getErrorSchemaPath(props, state.to.key).__errors;

  //const culture = props.intl && props.intl.locale ? props.intl.locale : navigator.language;
  return (
    <div className="calendar">
      <div className={"col-xs-12 col-md-3 " + (fromErrors && fromErrors.length ? "has-error" : "") + " " + (readonly ? "readonly" : "")}>
        <label className="control-label">
          {t("Widget.StartDate")}
          {isRequired(props, state.from.key) ? <span className="req"> *</span> : null}
        </label>
        <DatePicker
          // culture={culture} 
          value={state.from.value ? state.from.value : undefined || null}
          includeTime={false}
          min={state.from.min}
          max={state.from.max}
          onChange={onChangeFrom}
          valueFormat={{ month: "2-digit", day: "2-digit", year: "numeric" }}
          placeholder={placeholder}
          disabled={disabled}
          open={open}
          onToggle={(e) => {
            if ((nextView !== "decade" && nextView !== "year" && nextView !== "month") || (!fromDateNavigate && nextView === "month"))  {
              setopen(e);
            }
          }}
          calendarProps={{ 
            onNavigate: () => {
              setFromDateNavigate(true);
              setopen(true);
            }, 
            onViewChange: (nView)=>{
              setnextView(nView);
            }
          }}
          readOnly={readonly} />
        {errorList(fromErrors)}
      </div>
      <div className={"col-xs-12 col-md-3 " + (toErrors && toErrors.length ? "has-error" : "") + " " + (readonly ? "readonly" : "")}>
        <label className="control-label">
          {t("Widget.EndDate")}
          {isRequired(props, state.to.key) ? <span className="req"> *</span> : null}
        </label>
        <DatePicker
          // culture={culture}
          value={state.to.value ? state.to.value : undefined || null}
          includeTime={false}
          min={state.from.value}
          max={state.from.max}
          onChange={onChangeTo}
          valueFormat={{ month: "2-digit", day: "2-digit", year: "numeric" }}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readonly}
          open={openEndDate}
              onToggle={(e) => {
                if ((nextViewEndDate !== "decade" && nextViewEndDate !== "year" && nextViewEndDate !== "month") || (!toDateNavigate && nextViewEndDate === "month")) {
                  setopenEndDate(e);
                }
              }}
              calendarProps={{ 
              onNavigate: () => {
                setToDateNavigate(true);
                setopenEndDate(true);
              }, 
              onViewChange: (nView)=>{
                setnextViewEndDate(nView);
              }
            }}
        />
        {errorList(toErrors)}
      </div>
    </div>
  );
};

export default DateRangeField;