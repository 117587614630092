import React, { useState, useEffect, useRef } from 'react';

const RadioWidget = (props) => {
  const { disabled, readonly, autofocus=false, schema } = props;
  const [value, setValue] = useState(props.value);
  const [options] = useState(props.options.enumOptions);
  const selected = value || schema.default;

  // Generating a unique field name to identify this set of radio buttons
  const name = Math.random().toString();
  const { inline } = props.options;
  const radioRef = useRef();

  useEffect(() => {
    if (radioRef && isAutofocus()) {
      radioRef.current.focus();
    }
  }, []);

  const isAutofocus = () => {
    return props.autofocus;
  };

  const onChange = (value) => {
    setValue(value);
    if (props.onChange) {
      // Send value out with any processing needed
      props.onChange(value);
    }
    
  };

  const content =
    options && options.length
      ? options.map((option, i) => {
          const checked = option.value === selected;
          const disabledCls = disabled ? 'disabled' : '';
          const label = <span>{option.label}</span>;
          const radio = (
            <span key={i}>
              <input
                type='radio'
                checked={checked}
                name={name}
                value={option.value}
                disabled={disabled}
                autoFocus={autofocus && i === 0}
                onChange={() => onChange(option.value)}
                
              />
              {label}
            </span>
          );

          const control = inline ? (
            <label key={i} className={`radio-inline ${disabledCls}`}>
              {readonly ? label : radio}
            </label>
          ) : (
            <div key={i} className={`radio ${disabledCls}`}>
              <label>{readonly ? label : radio}</label>
            </div>
          );

          return !readonly || (readonly && checked) ? control : null;
        })
      : null;



  return (
    <div className='field-radio-group' ref={radioRef}>
      {content}
    </div>
  );
};
export default RadioWidget;
