import React from 'react';
import styles from './PercentageScoreWidget.scss';

const PercentageScoreWidget = (props) => {
  const drawPercentage = () => {
    const {
      size = 60,
      positiveBGColor = '#00a96f',
      neutralBGColor = '#eee',
      percentage
    } = props;

    let bgColor = neutralBGColor;
    if (percentage > 80) {
      bgColor = positiveBGColor;
    }

    return (
      <div
        className={styles.percentageCircle}
        style={{
          width: size,
          height: size,
          borderRadius: size,
          backgroundColor: bgColor
        }}
      />
    );
  };

  const writePercentage = () => {
    const { percentage } = props;
    return <div className={styles.percentage}>{percentage}%</div>;
  };

  return (
    <div className={styles.flexWrapper}>
      {drawPercentage()}
      {writePercentage()}
    </div>
  );
};

export default PercentageScoreWidget;
