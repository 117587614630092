import React from 'react';
import objectPath from 'object-path';
import update from 'immutability-helper';
import deepmerge from 'deepmerge';
import { getUiOptions } from 'react-jsonschema-form/lib/utils';

const pathIdentifier = 'key';

export function resolvePathSettings(props) {
  const disabled = isDisabled(props);
  const readonly = isReadonly(props);
  const autofocus = isAutofocus(props);

  return { disabled, readonly, autofocus };
}

export function isRequired(props, path) {
  const { schema } = props;
  if (schema) {
    let resolvedSchema = schema;
    let property = path;
    if (path.includes('.')) {
      const [head, ...trunc] = path.split('.').reverse();
      property = head;
      resolvedSchema = getSchemaPath(schema, trunc.reverse().join('.'));
    }

    if (resolvedSchema && Object.prototype.hasOwnProperty.call(resolvedSchema, "required")) {
      return resolvedSchema.required.includes(property);
    }
  }
  return false;
}

export function isDisabled(props) {
  return props && (props.disabled || (props.uiSchema && props.uiSchema.hasOwnProperty.call('ui:disabled')));
}

export function isReadonly(props) {
  return props && (props.readonly || (props.uiSchema && props.uiSchema.hasOwnProperty.call('ui:readonly')));
}

export function isAutofocus(props) {
  return props && props.uiSchema && props.uiSchema.schema && props.uiSchema.schema['ui:autofocus'];
}

export function getErrorSchemaPath(props, path) {
  const { errorSchema } = props;

  if (!path || !errorSchema || !Object.prototype.hasOwnProperty.call(errorSchema, path.split('.')[0])) {
    return {};
  }

  return objectPath.get(errorSchema, path) || {};
}

export function getPatch(path, value, spec) {
  let updatedPath = path;
  if (!isValidPatch(value)) {
    updatedPath += '.' + ( spec || '$set');
  }
  return createObjectFromPath(updatedPath, value);
}

export function getPush(path, value) {
  return getPatch(path, value, '$push');
}

export function getUnshift(path, value) {
  return getPatch(path, value, '$unshift');
}

export function getSplice(path, value) {
  return getPatch(path, value, '$splice');
}

export function getSet(path, value) {
  return getPatch(path, value, '$set');
}

export function getMerge(path, value) {
  return getPatch(path, value, '$merge');
}

export function getApply(path, value) {
  return getPatch(path, value, '$apply');
}

export function applyPatch(obj, patch) {
  return update(obj, patch);
}

export function isValidPatch(patch) {
  const allowedSpecs = ['$push', '$unshift', '$splice', '$set', '$merge', '$apply'];
  const specs = searchObj(patch, (property) => allowedSpecs.includes(property));
  return specs.length > 0;
}

export function getSchemaPath(obj, path) {
  function resolveRef(obj, ref) {
    return objectPath.get(obj, ref.substring(2).replace(/\//g, '.'));
  }

  const ref = '$ref';
  const elem = [];
  path.split('.').map((e) => {
    elem.push('properties');
    elem.push(e);
  });

  const result = elem.reduce((a, b) => {
    if (a[ref]) {
      a = resolveRef(obj, a[ref]);
    }
    return a[b];
  }, obj);

  if (!result) {
    throw new Error(`Unable to resolve path: ${path}`);
  } else if (result[ref]) {
    return resolveRef(obj, result[ref]);
  }

  return result;
}

export function getPath(obj, path) {
  return path.split('.').reduce((a, b) => a[b], obj);
}

export function getIdSchema(props, key) {
  return getPath(props.idSchema, key);
}

export function getOptions(uiSchema) {
  return getUiOptions(uiSchema);
}

export function searchObj(obj, check) {
  let arr = [];
  for (let property in obj) {
    if (!{}.hasOwnProperty.call(obj, property)) {
      continue;
    }
    if (check(property)) {
      arr.push(obj[property]);
    } else if (obj[property] instanceof Object) {
      arr = [...arr, ...searchObj(obj[property], check)];
    }
  }

  return arr;
}

export function findObjects(obj, check) {
  let arr = [];
  for (let property in obj) {
    if (!obj.hasOwnProperty.call(property)) {
      continue;
    }
    if (obj[property] instanceof Object) {
      if (check(obj[property])) {
        arr.push(obj[property]);
      }

      arr = [...arr, ...findObjects(obj[property], check)];
    }
  }

  return arr;
}

function flattenObj(obj) {
  const toReturn = {};

  for (let property in obj) {
    if (!obj.hasOwnProperty.call(property)) {
      continue;
    }
    if (typeof obj[property] === 'object') {
      var flatObject = flattenObj(obj[property]);
      for (let x in flatObject) {
        if (!flatObject.hasOwnProperty.call(x)) {
          continue;
        }
        toReturn[property + '.' + x] = flatObject[x];
      }
    } else {
      toReturn[property] = obj[property];
    }
  }
  return toReturn;
}

export function shouldComponentUpdatePatch(uiSchema, patch) {
  const schemaPaths = searchObj(uiSchema, (property) => property === pathIdentifier);
  const patchPaths = [];
  for (let property in flattenObj(patch)) {
    const elem = property.split('.');
    patchPaths.push(elem.slice(0, -1).join('.'));
  }

  return schemaPaths.filter((x) => patchPaths.includes(x)).length > 0;
}

function createObjectFromPath(path, value) {
  let root = {};
  let obj = root;
  path.split('.').forEach(function (element, index, array) {
    if (index === array.length - 1) {
      obj[element] = value;
    } else {
      obj[element] = {};
      obj = obj[element];
    }
  }, this);

  return root;
}

export function getFormDataFromUiSchema(formData, uiSchema) {
  const arrayKeys = ['arrayKey', 'optionsDataKey', 'optionsDataSeparator', 'dataKey'];
  const schemaPaths = searchObj(uiSchema, (property) => property === pathIdentifier || arrayKeys.includes(property));
  const sources = [];
  schemaPaths.map((path) => {
    const value = objectPath.get(formData, path);
    sources.push(createObjectFromPath(path, value));
  });

  if (sources.length > 1) {
    return deepmerge.all(sources);
  } else if (sources.length === 1) {
    return sources[0];
  }
}

export function errorList(errors) {
  if (!errors || !errors.length) {
    return null;
  }
  return (
      <React.Fragment>
        <div className='clearfix' />
        <div>
          <ul className='error-detail bs-callout bs-callout-info'>
            {errors.map((error, index) => {
              return (
                <li className='text-danger' key={index}>
                  {error}
                </li>
              );
            })}
          </ul>
        </div>
      </React.Fragment>
  );
}

export function defaultRadioButtonValues(formData, uiSchemas, schema) {
  let uiSchemasInput = uiSchemas;
  if (!(uiSchemas instanceof Array)) {
    uiSchemasInput = [uiSchemas];
  }
  const sources = [];
  uiSchemasInput.forEach((uiSchema) => {
    const radioButtons = findObjects(uiSchema, (obj) => {
      return obj.schema && obj.schema['ui:widget'] === 'radio';
    });
    radioButtons.filter( (rb) => {
      const rbSchema = getSchemaPath(schema, rb.key);
      return rbSchema && rbSchema.default;
    }).forEach((rb) => {
      const rbSchema = getSchemaPath(schema, rb.key);
      sources.push(createObjectFromPath(rb.key, rbSchema.default));
    });
  });

  let formDataInput = formData;
  if (sources.length > 0) {
    sources.push(formDataInput);
    formDataInput = deepmerge.all(sources);
  }

  return formDataInput;
}
