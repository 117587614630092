import React from 'react';
import Textarea from 'react-textarea-autosize';

const TextareaWidget = (props) => {
  const { autofocus, disabled, id, onBlur, options, placeholder, readonly, required, schema, value = '' } = props;

  const handleChange = (event) => {
    const { maxLength } = schema;
    const newValue = event.target.value;
    const { onChange } = props;

    if (!maxLength) {
      onChange(newValue);
      return;
    }

    const truncatedValue = newValue.length > maxLength ? newValue.substr(0, maxLength) : newValue;

    if (truncatedValue === value) return;

    onChange(truncatedValue);
  };

  return (
    <div className=''>
      {readonly ? (
        <pre className='pre-normal'>{value}</pre>
      ) : (
        <div>
          <Textarea
            id={id}
            className='form-control'
            value={value}
            placeholder={placeholder}
            required={required}
            disabled={disabled}
            readOnly={readonly}
            autoFocus={autofocus}
            rows={options.rows}
            onBlur={onBlur && ((event) => onBlur(id, event.target.value))}
            onChange={handleChange}
          />
          {schema?.maxLength && !readonly ? (
            <div className='text-right' id='the-count'>
              <span id='current'>{value?.length ?? 0}</span>
              <span id='maximum'>{'/' + schema.maxLength}</span>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

TextareaWidget.defaultProps = {
  autofocus: false,
  options: {}
};

export default TextareaWidget;
