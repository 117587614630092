import React from 'react';
import Form from 'react-jsonschema-form';
import {
  getDefaultRegistry,
  getDefaultFormState,
  retrieveSchema,
  setState,
  toIdSchema
} from 'react-jsonschema-form/lib/utils';
import pathFields from './fields';
import pathWidgets from './widgets';
import { isValidPatch, applyPatch, getFormDataFromUiSchema } from '../utils';
import { validateFormDataUiSchema } from '../validate';
import styles from './PathForm.scss';

const REQUIRED_FIELD_SYMBOL = '*';

function Label(props) {
  const { label, required, id } = props;
  return (
    <label className='control-label' htmlFor={id}>
      {required ? label + REQUIRED_FIELD_SYMBOL : label}
    </label>
  );
}

function CustomFieldTemplate(props) {
  const { id, classNames, label, children, errors, help, description, hidden, required, displayLabel } = props;
  if (hidden || classNames.includes('field-object')) {
    return children;
  }

  return (
    <div className={styles.pathForm}>
      {displayLabel && label && <Label label={label} required={required} id={id} />}
      {displayLabel && description && description.props.description ? description : null}
      {children}
      {errors.props.errors ? errors : null}
      {help.props.help ? help : null}
    </div>
  );
}

export default class PathForm extends Form {
  constructor(props) {
    super(props);
  }

  validate(formData, schema, uiSchema = this.props.uiSchema) {
    const { validate, transformErrors } = this.props;
    const result = validateFormDataUiSchema(formData, schema || this.props.schema, uiSchema, validate, transformErrors);

    return result;
  }

  getStateFromProps(props, inputFormData) {
    const state = this.state || {};
    const schema = 'schema' in props ? props.schema : this.props.schema;
    const uiSchema = 'uiSchema' in props ? props.uiSchema : this.props.uiSchema;
    const edit = typeof inputFormData !== 'undefined';
    const liveValidate = props.liveValidate || this.props.liveValidate;
    const mustValidate = edit && !props.noValidate && liveValidate;
    const { definitions } = schema;
    const formData = getDefaultFormState(schema, inputFormData, definitions);
    const retrievedSchema = retrieveSchema(schema, definitions, formData);
    const customFormats = props.customFormats;
    const additionalMetaSchemas = props.additionalMetaSchemas;
    const { errors, errorSchema } = mustValidate
      ? this.validate(formData, schema, additionalMetaSchemas, customFormats)
      : {
          errors: state.errors || [],
          errorSchema: state.errorSchema || {}
        };
    const idSchema = toIdSchema(retrievedSchema, uiSchema['ui:rootFieldId'], definitions, formData, props.idPrefix);

    return {
      status: 'initial',
      schema,
      uiSchema,
      idSchema,
      formData,
      edit,
      errors,
      errorSchema,
      additionalMetaSchemas
    };
  }

  onChange = (patch, options = { validate: false }) => {
    const mustValidate = !this.props.noValidate && (this.props.liveValidate || options.validate);
    let formData = isValidPatch(patch) ? applyPatch(this.state.formData, patch) : patch;
    formData = getFormDataFromUiSchema(formData, this.props.uiSchema);
    let state = { status: 'editing', formData };
    if (mustValidate) {
      const { errors, errorSchema } = this.validate(formData);
      state = { ...state, errors, errorSchema };
    }

    setState(this, state, () => {
      if (this.props.onChange) {
        this.props.onChange({ formData });
      }
    });
  };

  getRegistry() {
    // For BC, accept passed SchemaField and TitleField props and pass them to
    // the "fields" registry one.
    const { fields, widgets } = getDefaultRegistry();

    return {
      fields: { ...fields, ...pathFields, ...this.props.fields },
      widgets: { ...widgets, ...pathWidgets, ...this.props.widgets },
      ArrayFieldTemplate: this.props.ArrayFieldTemplate,
      ObjectFieldTemplate: this.props.ObjectFieldTemplate,
      FieldTemplate: this.props.FieldTemplate || CustomFieldTemplate,
      definitions: this.props.schema.definitions || {},
      formContext: this.props.formContext || {}
    };
  }
}
