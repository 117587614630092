import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import objectPath from 'object-path';
import styles from './TextDynamicField.scss';

const TextDynamicField = (props) => {
  const derivedText = props.uiSchema.dataKey && objectPath.get(props.formData, props.uiSchema.dataKey) ? objectPath.get(props.formData, props.uiSchema.dataKey) : props.uiSchema.label;
  const [icon] = useState(props.uiSchema.icon);

  const [text] = useState(derivedText || "");
  const [translated] = useState(props.uiSchema.translated);
  const { t } = useTranslation();

  return (
    <div className={styles.textDynamic}>
      <span> {props.uiSchema.label}</span>
      {!icon ? null : (
        <span className={styles.icon}>
          <span className={'qjicon-' + icon}> </span>
        </span>
      )}
      {!text ? null : <span className={styles.text}>{translated ? t(text) : <span>{text}</span>}</span>}
    </div>
  );
};

export default TextDynamicField;
