import ContactWidget from './ContactWidget';
import DateTimeWidget from './DateTimeWidget';
import ListTagsWidget from './ListTagsWidget';
import ListTagWidget from './ListTagWidget';
import NumericWidget from './NumericWidget';
import PercentageScoreWidget from './PercentageScoreWidget';
import ProfilePictureWidget from './ProfilePictureWidget';
import ProfileWidget from './ProfileWidget';
import RadioWidget from './RadioWidget';
import RteWidget from './RteWidget';
import SearchWordInput from './SearchWordInput';
import SelectMultiWidget from './SelectMultiWidget';
import SelectWidget from './SelectWidget';
import TextareaWidget from './TextareaWidget';
import ToggleSliderWidget from './ToggleSliderWidget';
import TagInputWidget from './TagInputWidget';
import SkillTagInputWidget from './SkillTagInputWidget';
import DateCalendarWidget from './DateCalendarWidget';

export default {
  ContactWidget,
  DateTimeWidget,
  ListTagsWidget,
  ListTagWidget,
  NumericWidget,
  PercentageScoreWidget,
  ProfilePictureWidget,
  ProfileWidget,
  RadioWidget,
  RteWidget,
  SearchWordInput,
  SelectMultiWidget,
  SelectWidget,
  SkillTagInputWidget,
  TagInputWidget,
  TextareaWidget,
  ToggleSliderWidget,
  DateCalendarWidget,
  // for compatibility with existing schemas
  contactWidget: ContactWidget,
  datetime: DateTimeWidget,
  listTagsWidget: ListTagsWidget,
  listTagWidget: ListTagWidget,
  number: NumericWidget,
  percentageScoreWidget: PercentageScoreWidget,
  profilePictureWidget: ProfilePictureWidget,
  profileWidget: ProfileWidget,
  radioWidget: RadioWidget,
  rte: RteWidget,
  searchWordInput: SearchWordInput,
  select: SelectWidget,
  selectMulti: SelectMultiWidget,
  skillTagInputWidget: SkillTagInputWidget,
  tagInputWidget: TagInputWidget,
  textareaWidget: TextareaWidget,
  toggleSlider: ToggleSliderWidget,
  dateCalendar: DateCalendarWidget
};
