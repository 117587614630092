import toPath from "lodash.topath";
import { searchObj } from "./utils";
import { validate as jsonValidate, ValidationError } from "jsonschema";

import { isObject, mergeObjects } from "react-jsonschema-form/lib/utils";

// Default transformation of Errors
export function defaultTransformErrors(errors) {
  return errors.map(error => {
    switch (error.name) {
      case "enum": {
        error.message = "Please select an option";
        break;
      }
      case "minLength": {
        if (error.argument === 1) {
          error.message = "Field is required";
        }
        break;
      }
      case "required": {
        error.message = "Field is required";
        break;
      }
    }
    return error;
  });
}

function keyExists(error, arr) {
  const existList = arr.filter(key => {
    const fqKey = "instance." + key;
    return error.property === fqKey || error.property + "." + error.argument === fqKey || (error.property.startsWith(fqKey) && error.property.charAt(fqKey.length) === "[");
  });

  return existList.length > 0;
}

// Validate only current form uiSchema
export function validateFormDataUiSchema(formData, schema, uiSchema, customValidate, transformErrors) {
  const validation = validateFormData(formData, schema, customValidate, transformErrors);
  const arr = searchObj(uiSchema, property => property === "key");
  const errors = validation.errors.filter(error => keyExists(error, arr));
  const errorSchema = toErrorSchema(errors);

  return { errorSchema, errors };
}

function toErrorSchema(errors) {
  if (!errors.length) {
    return {};
  }
  return errors.reduce((errorSchema, error) => {
    const { property, message, name, argument } = error;
    let extraPath = "";
    if (name === "required") {
      extraPath = "." + argument;
    }
    const path = toPath(property + extraPath);
    let parent = errorSchema;
    for (const segment of path.slice(1)) {
      if (!(segment in parent)) {
        parent[segment] = {};
      }
      parent = parent[segment];
    }
    if (Array.isArray(parent.__errors)) {
      parent.__errors = parent.__errors.concat(message);
    } else {
      parent.__errors = [message];
    }
    return errorSchema;
  }, {});
}

function validateFormData(formData, schema, customValidate, transformErrors) {
  let { errors } = jsonValidate(formData, schema);
  if (typeof transformErrors === "function") {
    errors = transformErrors(errors);
  } else {
    errors = defaultTransformErrors(errors);
  }
  const errorSchema = toErrorSchema(errors);

  if (typeof customValidate !== "function") {
    return { errors, errorSchema };
  }

  const errorHandler = customValidate(formData, createErrorHandler(formData, schema));
  const userErrorSchema = unwrapErrorHandler(errorHandler);
  const newErrorSchema = mergeObjects(errorSchema, userErrorSchema, true);

  const newErrors = toErrorList(errorHandler);

  return { errors: [...errors, ...newErrors], errorSchema: newErrorSchema };
}

function createErrorHandler(formData, schema, propertyPath = "instance") {
  const handler = {
    __errors: [],
    addError(message, formData) {
      const err = new ValidationError(message, formData, schema, propertyPath, "custom");
      this.__errors.push(err);
    }
  };
  if (isObject(formData)) {
    return Object.keys(formData).reduce((acc, key) => {
      return {
        ...acc,
        [key]: createErrorHandler(formData[key], schema, propertyPath + "." + key)
      };
    }, handler);
  }
  return handler;
}

function unwrapErrorHandler(errorHandler) {
  return Object.keys(errorHandler).reduce((acc, key) => {
    if (key === "addError") {
      return acc;
    } else if (key === "__errors") {
      return { ...acc, [key]: errorHandler[key].map(e => e.message) };
    }
    return { ...acc, [key]: unwrapErrorHandler(errorHandler[key]) };
  }, {});
}

function toErrorList(errorHandler) {
  let errorList = [];
  if ("__errors" in errorHandler) {
    errorList = errorList.concat(errorHandler.__errors);
  }
  return Object.keys(errorHandler).reduce((acc, key) => {
    if (key !== "__errors") {
      acc = acc.concat(toErrorList(errorHandler[key]));
    }
    return acc;
  }, errorList);
}
