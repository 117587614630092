import AccordionField from "./AccordionField";
import CharacterInputField from "./CharacterInputField";
import CheckboxDynamicField from "./CheckboxDynamicField";
 import DateRangeField from "./DateRangeField";
 import PathArrayField from "./PathArrayField";
import PathField from "./PathField";
import SectionField from "./SectionField";
import SelectDynamicField from "./SelectDynamicField";
 import TextDynamicField from "./TextDynamicField";

export default {
  AccordionField,
  CheckboxDynamicField,
  DateRangeField,
   PathArrayField, // Preeti
  PathField, // Preeti
  SectionField,
  SelectDynamicField,
  TextDynamicField,
  // for compatibility with existing schemas
  keyed: PathField,
  CharacterInput: CharacterInputField,
  section: SectionField,
   accordion: AccordionField,
   dateRange: DateRangeField,
   selectDynamic: SelectDynamicField,
   textDynamic: TextDynamicField,
   checkboxDynamic: CheckboxDynamicField,
   checkbox: CheckboxDynamicField,
   keyedArray: PathArrayField
};
