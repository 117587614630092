import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import objectPath from 'object-path';
import { DropdownList } from 'react-widgets';
import { isRequired, resolvePathSettings, errorList, getErrorSchemaPath, getPatch } from '../../utils';

const SelectDynamicField = (props) => {
  const optionsData = objectPath.get(props.formData, props.uiSchema.optionsDataKey);

  const [key] = useState(props.uiSchema.key);
  const [value, setValue] = useState(objectPath.get(props.formData, props.uiSchema.key));
  const [options, setOptions] = useState(optionsData ? optionsData.split( props.uiSchema.optionsDataSeparator) || [] : []);
  const [placeholder] = useState(props.placeholder || props.defaultPlaceholder);
  const [optionsDataKey] = useState(props.uiSchema.optionsDataKey);
  const [optionsDataSeparator] = useState(props.uiSchema.optionsDataSeparator);

  const { t } = useTranslation();
  const dropDownListRef = useRef();

  // useEffect(() => {
  //   const { autofocus } = resolvePathSettings(props);
  //   if ( dropDownListRef && dropDownListRef.current && autofocus) {
  //     dropDownListRef.current.focus();
  //   }
  // },[]);

  // for componentWillReciveProps
  useEffect(() => {
    let valueData = value;
    const optionsDataText = objectPath.get(props.formData, optionsDataKey);
    const optionsData = optionsDataText ? optionsDataText.split(optionsDataSeparator) || [] : [];
    
    const { readonly } = resolvePathSettings(props);

    if (readonly) {
      // Readonly so keep the state synced
      valueData = objectPath.get(props.formData, key);
    }
    setValue(valueData);
    setOptions(optionsData);
  }, [props]);

  const onChange = (value) => {
    setValue(value);
    if (props.onChange) {
      // Send value out with any processing needed
      props.onChange(getPatch(key, value.value));
    }
    
  };

  const errors = getErrorSchemaPath(props, key).__errors;
  const { disabled, readonly } = resolvePathSettings(props);
  const { uiSchema } = props;
  const derivedKey = uiSchema.arrayKey ? uiSchema.arrayKey + "." + uiSchema.key : uiSchema.key;
  const optionsMap = options.map(o => {
    return { value: o, label: o };
  });
  return (
    <div className={(errors ? 'has-error' : '') + ' ' + (readonly ? 'readonly' : '')}>
      <label className='control-label'>
        {t(derivedKey)}
        {isRequired(props, key) ? <span className='req'> *</span> : null}
      </label>
      {optionsMap ? (
        optionsMap.length && (!readonly || (readonly && value)) ? (
          <DropdownList
            dataKey={'value'}
            textField='label'
            placeholder={placeholder}
            value={value}
            data={optionsMap}
            onChange={onChange}
            disabled={disabled}
            readOnly={readonly}
            ref={dropDownListRef}
          />
        ) : null
      ) : null}
      {errorList(errors)}
    </div>
  );
};

export default SelectDynamicField;
