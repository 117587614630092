import React from 'react';
import ListTagWidget from './ListTagWidget';
import styles from './ListTagsWidget.scss';

const ListTagsWidget = (props) => {
  const getTags = () => {
    const { glyphicon = '', handleTagClick, maxTags = 3, styledTags = null, tags = null, typeName = '' } = props;

    if (styledTags?.length > 0) {
      const tagsList = styledTags.slice(0, maxTags).map((tag, i) => {
        return <ListTagWidget glyphicon={tag.glyphicon} isHighlighted={tag.isHighlighted} key={i} name={tag.name} />;
      });
      const extraCount = styledTags.length - maxTags;

      return (
        <div>
          {tagsList}
          {extraCount > 0 ? (
            <ListTagWidget name={`+ ${extraCount} more ${typeName}`} handleTagClick={handleTagClick} />
          ) : null}
        </div>
      );
    }

    let listTags = <span />;
    let moreTags = <span />;

    if (tags?.length > 0 || styledTags?.length > 0) {
      listTags = tags.slice(0, maxTags).map((tag, i) => {
        return <ListTagWidget key={i} name={tag} glyphicon={glyphicon} />;
      });

      if (tags.length > maxTags) {
        let extraCount = tags.length - maxTags;
        moreTags = <ListTagWidget name={`+ ${extraCount} more ${typeName}`} handleTagClick={handleTagClick} />;
      }
    }

    return (
      <div>
        {listTags}
        {moreTags}
      </div>
    );
  };

  return <div className={styles.listTags}>{getTags()}</div>;
};

export default ListTagsWidget;
