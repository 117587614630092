import React, { useState } from 'react';
import styles from './SearchWordInput.scss';

const SearchWordInput = React.forwardRef((props, ref) => {
  const [searchWords, setSearchWords] = useState(props.value ? props.value : []);
  const [value, setValue] = useState('');
  const [showingMore] = useState(false);
  const [filterKey] = useState(props.filterKey);

  const renderSearchWord = () => {
    const { maxTags = 3 } = props;

    let keyCounter = 0;
    let searchWordsCount = 0;
    searchWordsCount = searchWords.length;

    let renderedSearchWords = searchWords.map((word, i) => {
      if (keyCounter < searchWordsCount) {
        if (keyCounter < maxTags) {
          let removeTags = null;
          removeTags = (
            <span className={styles.closeTag} onClick={() => removeTag(i)}>
              &nbsp;<span className={styles.closeTagGlyph}>&times;</span>&nbsp;
            </span>
          );
          return (
            <span key={keyCounter++} className={styles.searchTag}>
              &nbsp;{word}&nbsp;{removeTags}
            </span>
          );
        }
        if (i === maxTags) {
          let numberMore = searchWordsCount - maxTags;
          return (
            <span key={keyCounter++} className={styles.searchTagmore}>
              +{numberMore} More&nbsp;
            </span>
          );
        }
      }
    });
    return <span>{renderedSearchWords}</span>;
  };

  const removeTag = (index) => {
    let currentSearchWords = searchWords;
    currentSearchWords.splice(index, 1);

    setSearchWords(currentSearchWords);
    let obj = {
      searchWords,
      value,
      showingMore,
      filterKey
    };
    props.onChange(obj);
  };

  const handleEntry = (event) => {
    setValue(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && value !== null && value !== '') {
      // Important: read `prevState` instead of `this.state` when updating.
      searchWords.push(value);
      setValue('');
      let obj = {
        searchWords,
        value,
        showingMore,
        filterKey
      };
      if (!props.dontCallEnterChange) {
        props.onChange(obj);
      }
    }
  };

  const handleBlur = (e) => {
    if (value !== null && value !== '') {
      // Important: read `prevState` instead of `this.state` when updating.
      searchWords.push(value);
      setValue('');
      const obj = {
        searchWords,
        value,
        showingMore,
        filterKey
      };
      props.onChange(obj);
    } else if (props.dontCallEnterChange && searchWords && searchWords.length > 0) {
      const obj = {
        searchWords,
        value,
        showingMore,
        filterKey
      };
      props.onChange(obj);
    }
  };

  const { containerStyles, inputStyles, placeholder, searchWordLimit } = props;
  const displayInput = !searchWordLimit || searchWords?.length === 0 || searchWordLimit < searchWords.length;

  return (
    <div className={containerStyles ?? styles.container} ref={ref}>
      {renderSearchWord()}
      {displayInput ? (
        <input
          data-testid='searchWordInput'
          type='text'
          className={inputStyles ?? styles.input}
          onChange={handleEntry}
          onKeyPress={handleKeyPress}
          value={value}
          onBlur={handleBlur}
          placeholder={placeholder}
        />
      ) : null}
      <input type='hidden' value={value} />
    </div>
  );
});

export default SearchWordInput;
