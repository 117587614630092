import React from 'react';
import { useTranslation } from 'react-i18next';
import { getFormDataFromUiSchema } from '../../utils';

const SectionField = (props) => {
  const {
    uiSchema,
    errorSchema,
    idSchema,
    name,
    required,
    disabled,
    readonly,
    schema,
    formData,
    registry,
    autofocus,
    onBlur
  } = props;
  const { SchemaField } = props.registry.fields;

  const { t } = useTranslation();

  let content = uiSchema.content.map((item, index) => {
    return (
      <SchemaField
        key={index}
        name={name}
        required={required}
        schema={schema}
        uiSchema={item}
        errorSchema={errorSchema}
        idSchema={idSchema}
        formData={getFormDataFromUiSchema(formData, item)}
        onChange={props.onChange}
        registry={registry}
        disabled={disabled}
        readonly={readonly}
        autofocus={autofocus}
        onBlur={onBlur}
      />
    );
  }, this);

  return (
    <div className={uiSchema.className}>
      {uiSchema.title ? <h3>{t(uiSchema.title)}</h3> : null}
      {uiSchema.description ? <p className='description'>{t(uiSchema.description)}</p> : null}
      {content}
    </div>
  );
};

export default SectionField;
